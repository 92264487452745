import React from 'react';
import { connect } from 'react-redux';
import { onGetScenarios, onGetCount, onSelectedScenario } from "../../../actions/scenarioActions"
import { deleteScenario, playScenario, duplicateScenario, editScenario, closeScenario, setOnPlayOrOnPauseScenario } from '../../../services/scenarioServices'
import  Pause  from "../../../assets/pause.svg"
import  Play  from "../../../assets/tdb/play.svg"
import  Delete  from "../../../assets/tdb/delete.svg"
//import Edit  from "../../../assets/tdb/modiifier.svg"
import  Lock  from "../../../assets/tdb/lock.png"
import  Add from "../../../assets/tdb/panel.svg"
import  AddIcon  from "../../../assets/ajouter.svg"
import { Col, Progress } from "reactstrap"
import { onGetSubClients } from '../../../actions/userActions'
import moment from 'moment';
import "../dashboard.css"
import "../../scenario/scenario.css"
import ReactTooltip from 'react-tooltip';
import ClickableTable from "../../common/clickableTable"
import { toast } from 'react-toastify';
import RightStat from './RightStat';
import Favorite from '../../common/favorite'
import RendreDeleteModal from './Modals/DeleteModal';
import RendreDuplicateModal from './Modals/DuplicateModal';
import RendreLoadingModal from './Modals/LoadingModal';
import RendreClotureModal from './Modals/ClotureModal';
import { changeActiveItemTabDashboard, changeActiveMenuTabDashboard } from "../../../actions/authActions";

import { Tag } from 'antd';

import './dashboard.css'

class DashboardClient extends React.Component {
    state = {
        open: false,
        data: [],
        showDelete: false,
        scenarioId: null,
        comptesActifs: 1,
        date: moment().format('LLL'),
        currentScenario: {
            oldName: "",
            newName: "",
        },
        scenarioLabel: {
            oldName: "Nom du scénario actuel",
            newName: "Nouveau nom du scénario",
        },
        textError: "",
        showClose: false,
    }

    componentDidMount() {

        let scenarios = []
        if (this.props.scenario.scenarios === undefined || this.props?.scenario?.scenarios?.length === 0) {
            this.props.onGetScenarios()
            this.props.onGetCount()
            this.props.onGetSubClients()
        }
        else {
            this.props.onGetCount()
            this.props.onGetScenarios()
            this.props.scenario.scenarios.map(scenario => 
                scenarios = [...scenarios, {
                    id: scenario.id,
                    createdAt: scenario.createdAt,
                    product: scenario.product,
                    isModerate: scenario.isModerate,
                    title: scenario.title,
                    progress: scenario.progress,
                    isUnique: scenario.isUnique,
                    state: scenario.state,
                    panel: scenario.panel,
                    etat: scenario.etat,
                    steps : scenario.steps,
                    color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                }]
            )
            this.setState({ data: scenarios, count: this.props.scenario.count })
        }
        this.props.changeActiveItemTabDashboard("Tableau de Bord")
        this.props.changeActiveMenuTabDashboard("")
    }

    componentDidUpdate(prevProps) {
        if (this.props.scenario.scenarios !== prevProps.scenario.scenarios) {
            this.props.onGetCount()
            let scenarios = []
            if (this.props.scenario.scenarios){
                this.props.scenario.scenarios.map(scenario => 
                    scenarios = [...scenarios, {
                        id: scenario.id,
                        createdAt: scenario.createdAt,
                        product: scenario.product,
                        isModerate: scenario.isModerate,
                        title: scenario.title,
                        progress: scenario.progress,
                        isUnique: scenario.isUnique,
                        state: scenario.state,
                        panel: scenario.panel,
                        etat: scenario.etat,
                        steps : scenario.steps,
                        color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                    }]
                )
            }
            this.setState({ data: scenarios, count: this.props.scenario.count })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scenario.scenarios !== nextProps.scenario.scenarios) {
            this.props.onGetCount()
            let scenarios = []
            if (nextProps.scenario.scenarios){
                nextProps.scenario.scenarios.map(scenario => 
                    scenarios = [...scenarios, {
                        id: scenario.id,
                        createdAt: scenario.createdAt,
                        product: scenario.product,
                        isModerate: scenario.isModerate,
                        title: scenario.title,
                        progress: scenario.progress,
                        isUnique: scenario.isUnique,
                        state: scenario.state,
                        panel: scenario.panel,
                        etat: scenario.etat,
                        steps : scenario.steps,
                        color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
                    }]
                )
            }
            this.setState({ data: scenarios, count: this.props.scenario.count })
        }
    }

    handleOpenDeleteScenario = (id, etat) => {
        if( etat < 3 ){
            this.setState({ showDelete: true })
            this.setState({ scenarioId: id })
        }
        return
    }

    handleCloseDeleteScenario = () => {
            this.setState({ showDelete: false })
            this.setState({ scenarioId: null })

    }

    dropScenario = async () => {
        await deleteScenario(this.state.scenarioId).then(res => {
            this.props.onGetScenarios()
            this.props.onGetCount()
            toast.success('Le scénario a été supprimé')
        })
        this.setState({ count: this.props.scenario.count })
        this.setState({ showDelete: !this.state.showDelete })
        this.setState({ scenarioId: null })
    }

    toggleOpen = () => {
        this.setState({ open: !this.state.open })
    }

    playAndPauseScenario = async (id, etat, progress) => {
        if(etat === 2){
            this.toggleOpen()
            try {
                const res = await playScenario(id);
                if (res.header.code !== 200) {
                    toast.error(res.header.message)
                    setTimeout(() => { 
                        this.toggleOpen();
                    }, 2000)
                }else {
                    this.props.onGetScenarios();
                    toast.success(res.header.message);
                    setTimeout(() => { 
                        this.toggleOpen();
                    }, 2000)
                }
            }catch (error) {
                console.log(error)
            }
        }else if(etat === 3 && progress !== 100 ){
            // try {
            //     const res = await this.pauseScenario(id);
            //     if (res.header.code !== 200) {
            //         toast.error(res.header.message)
            //         setTimeout(() => { 
                    //     this.toggleOpen() 
                    // }, 2000)
            //     }else {
            //         this.props.onGetScenarios();
            //         toast.success('Le scénario a été mis en pause')
            //         setTimeout(() => { 
                            //this.toggleOpen() 
                //     }, 2000)
            //     }
            // }catch (error) {
            //     console.log(error)
            // }
        }else{
            return
        }
    }

    SetOnPauseOnPlayScenario = async (id) =>{
        try {
            const res = await setOnPlayOrOnPauseScenario(id);
            if (res.header.code !== 200) {
                toast.error(res.header.message)
                setTimeout(() => { 
                    this.toggleOpen();
                }, 2000)
            }else {
                this.props.onGetScenarios();
                toast.success(res.response);
            }
        }catch (error) {
            console.log(error)
        }
    };

    onChangeScenario = (e) => {
        this.setState({ currentScenario: { ...this.state.currentScenario, newName: e.target.value } })
    }

    showDetails = (data) => {
        this.setState({
            show: !this.state.show, newScenario: data, currentScenario: {
                ...this.state.currentScenario, oldName: data?.title, newName: ""
            }
        })
    }

    onSubmit = async () => {
        if (this.props.scenario.scenarios.filter(scenario => scenario.title.toLowerCase() === this.state.currentScenario.newName.toLowerCase()).length > 0) {
            this.setState({ error: true, textError: "Ce scénario existe déjà" })
            setTimeout(() => {
                this.setState({ error: false, textError: "" })
            }, 3000)
        }
        else {
            await duplicateScenario(this.state.newScenario?.id).then(async res => {
                await editScenario(res.id, { title: this.state.currentScenario.newName }).then(res => {
                    this.setState({ show: !this.state.show })
                    this.props.onGetScenarios()
                    toast.success('Scénario dupliqué avec succés')
                })
            },)
            //remettre le state à zéro
            this.setState({ currentScenario: { ...this.state.currentScenario, newName: "" } })
        }
        setTimeout(() => {
            this.setState({ currentScenario: { ...this.state.currentScenario, newName: "" } })
        }, 3000)
    }


    handleToggleCloseScenario = (id) => {
        this.setState({ showClose: !this.state.showClose })
        this.setState({ scenarioId: id })
    }

    handleCloseScenario = async () => {
        try {
            this.toggleOpen() 
            console.log(this.state.scenarioId);
            const res = await closeScenario(this.state.scenarioId);
            if (res.header.code !== 200) {
                toast.error(res.header.message)
                setTimeout(() => { 
                    this.toggleOpen() 
                }, 2000)
            }else {
                this.props.onGetScenarios();
                toast.success(res.header.message);
                this.setState({ showClose: !this.state.showClose })
                setTimeout(() => { 
                        this.toggleOpen() 
                }, 2000)
            }
            this.setState({ scenarioId: null })
        }catch (error) {
            toast.error(error)
            console.log(error);
            setTimeout(() => { 
                    this.toggleOpen() 
            }, 2000)
        }
    }

    handleScenarioL = () => {
        const scenario = this.props.scenario.scenarios;
        //remove etat 0 et 1 
        if(scenario){
            const scenarioSans0et1 = scenario?.filter(scenario => scenario.etat !== 0 && scenario.etat !== 1)
            const scenarioL = scenarioSans0et1?.sort((a, b) => a.etat - b.etat)
            //push etat 0 et 1 at the end
            scenarioL.push(...scenario?.filter(scenario => scenario.etat === 0 || scenario.etat === 1))
            this.setState({ data: scenarioL })
        }
    }

    handleScenarioC = () => {
        const scenario = this.props.scenario.scenarios;
        if(scenario){
            const scenarioL = scenario.sort((a, b) => b.etat - a.etat)
            this.setState({ data: scenarioL })
        }
    }

    onRowClick = (data) => {

        this.props.onSelectedScenario(data);
        this.props.history.push({
            pathname: "/client/statistics",
            state: {
                selectedScenario: data
            }
        })
    }

    handleEtatScenario = (etat, progress) => {
        switch (etat) {
            case 0:
                return <Tag color='red'> A compléter </Tag>
            case 1:
                return <Tag color='magenta'> A associer </Tag>
            case 2:
                return <Tag color="blue"> A lancer</Tag>
            case 3:
                return <Tag color="orange"> En cours </Tag>
            case 4:
                return <Tag color="green"> Terminé </Tag>
            case 5:
                return <Tag color="green"> Cloturé </Tag>
            case 6 :
                return <Tag color="purple"> En pause </Tag>
            default:
                return <Tag color="orange"> Erreur </Tag>
        }
    }

    handleTypologie = (isUnique, isModerate, etat) => {
        return (
            <span className={etat === 2 && 'bold'}>
                {(isUnique === true && isModerate === true) ?
                    <div style={{ display: 'flex', flexDirection: "column", gap: 1 }}><div>Unique </div> <div>Modéré</div></div> :
                    (isUnique === true && isModerate === false) ?
                        <div style={{ display: 'flex', flexDirection: "column", gap: 1 }}><div>Unique </div> <div>Non Modéré</div></div> :
                        (isUnique === false && isModerate === true) ?
                            <div style={{ display: 'flex', flexDirection: "column", gap: 1 }}><div>A/B Testing </div> <div>Modéré</div></div> :
                            <div style={{ display: 'flex', flexDirection: "column", gap: 1 }}><div>A/B Testing </div> <div>Non Modéré</div></div>}
            </span>
        )
    }


    handleCreePanel = (rowData) => {
        this.props.history.push({
            pathname: '/client/testerpanel',
            state: rowData
        })
    }

    renderTableClickable = () => {
        const columns = [
            {
                title: "Date", field: 'createdAt',
                DateSort: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
                render: rowData =>
                    <span className={rowData.etat === 2 && 'bold'} >{rowData.createdAt}</span>
            },
            {
                title: "Nom de scénario", field: 'title', 
                render: rowData => <span className={rowData.etat === 2 && 'bold'} >{rowData.title}</span>
            },
            {
                title: "Produit", field: 'product', 
                render: rowData => <span className={rowData.etat === 2 && 'bold'} >{rowData.product}</span>
            },
            {
                title: "Typologie", field: 'isUnique',
                render: rowData => this.handleTypologie(rowData.isUnique, rowData.isModerate, rowData.etat)
            },
            {
                title: "Avancement", field: '', 
                render: rowData => <Progress className='progress_bar' color="success" value={rowData.progress ? rowData.progress : 0}>{rowData.progress}%</Progress>
            },
            {
                title: "Etat", field: 'etat',
                render: rowData => this.handleEtatScenario(rowData.etat, rowData.progress)
            },
        ]
        const actions = [
            {
                icon: () => <img src={AddIcon} alt="insightData duplicate" className='tableIcons2' style={{ marginTop: '.1rem' }}/>,
                tooltip: 'Dupliquer',
                onClick: (event, rowData) => this.showDetails(rowData),
            },
            rowData => ({
                icon: () => <img src={Delete} alt="insightData delete" className={`${[3, 4, 5, 6].includes(rowData.etat) ? 'tableIcons1 tableIconsDisabled' : 'tableIcons1'}`} />,
                tooltip: 'Supprimer',
                onClick: (event, rowData) => rowData.etat !== 3 || rowData.etat !== 5 || rowData.etat !== 6 ? this.handleOpenDeleteScenario(rowData.id, rowData.etat) : null,
            }),

            rowData => ({
                icon: () => <img src={Add} alt='insightData associer' className={`${ rowData.progess === 100 || rowData.etat === 1 ? 'tableIcons1' : 'tableIcons1 tableIconsDisabled'}`} />,
                tooltip:  'Panels',
                onClick: (event, rowData) => rowData.progess === 100 || rowData.etat === 1 ? this.handleCreePanel(rowData) : null,
            }),

            rowData => ({
                icon: () =>
                    rowData.etat === 3 ? 
                        <img src={Pause} alt="insightData pause" className={`${rowData.etat < 2 || rowData.progress === 100 || rowData.progress === 0 ? 'tableIcons2 ' : 'tableIcons2'}`} />
                    : 
                        <img src={Play} alt="insightData play"  className={`${[0, 1, 4, 5].includes(rowData.etat)  ? 'tableIcons1 tableIconsDisabled' : 'tableIcons1'}`} />,
                tooltip: rowData.etat === 3 ? 'Pause' : 'Play',
                onClick: (event, rowData) => {
                    switch (rowData.etat) {
                        case 2:
                            this.playAndPauseScenario(rowData.id, rowData.etat, rowData.progress);
                            break;
                        case 3:
                            this.SetOnPauseOnPlayScenario(rowData.id);
                            break;
                        case 6:
                            this.SetOnPauseOnPlayScenario(rowData.id);
                            break;
                        default:
                            break;
                    }
                },
            })
            ,
            rowData => ({
                icon: () => <img src={Lock} className={`${ rowData?.etat === 3 ? 'tableIcons1' : 'tableIcons1 tableIconsDisabled'}`} />,
                tooltip: 'Cloturer',
                onClick: (event, rowData) => rowData?.etat === 3 ? this.handleToggleCloseScenario(rowData.id) : null ,
            }),
        ]

        return (
            <ClickableTable
                title="Liste des scénarios"
                columns={columns}
                data={this.state.data}
                actions={actions}
                onRowClick={(event, rowData) => rowData.progress && this.onRowClick(rowData)}
            />
        )
    }
    render() {
        return (
            <>
                <div className='display__flex__row-dashboard_client'>
                    <Col md='10' className='dashboard_client'>
                        <Favorite />
                        <div className='tableContainer mt-4'>
                            {this.renderTableClickable()}
                        </div>
                    </Col>
                    <Col md='2' className='dashboard_client-right_side'>
                        <RightStat handleScenarioC={this.handleScenarioC} handleScenarioL={this.handleScenarioL} />
                    </Col>
                </div>
                {
                    this.state.show &&  <RendreDuplicateModal 
                                            show={this.state.show} 
                                            onToggleShowDetails={this.showDetails} 
                                            onChangeScenario={this.onChangeScenario} 
                                            currentScenario={this.state.currentScenario} 
                                            scenarioLabel={this.state.scenarioLabel} 
                                            onSubmit={this.onSubmit} 
                                            textError={this.state.textError}
                                        />
                }
                {   
                    this.state.open && <RendreLoadingModal open={this.state.open} onToggleClose={this.toggleOpen} />
                }
                {
                    this.state.showDelete &&<RendreDeleteModal 
                                                showDeleteModal={this.state.showDelete} 
                                                onToggleDeleteScenario={this.handleCloseDeleteScenario} 
                                                onDeleteScenario={this.dropScenario}  
                                            />
                }
                {
                    this.state.showClose && <RendreClotureModal 
                                                showClose={this.state.showClose} 
                                                onToggleCloseScenario={this.handleToggleCloseScenario} 
                                                onCloseScenario={this.handleCloseScenario} 
                                            /> 
                }
                <ReactTooltip />
            </>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    { onGetScenarios, onGetCount, onGetSubClients, onSelectedScenario, changeActiveItemTabDashboard, changeActiveMenuTabDashboard }
)(DashboardClient);