import React from 'react'
import { Table } from 'reactstrap';
import { formatDateHeurs, formatDateJour } from '../../utils/helpers';


const smallThTdStyle = {
    padding: '3px',
    textAlign: 'center',
};

/**
 * A component to display a table of emotions for a given face shot.
 *
 * @param {Object} currentFaceShot - The face shot for which to display the emotions.
 * @return {JSX.Element} The rendered table component.
 */
const EmotionTable = ({currentFaceShot}) => {
    /* RENDER */
    return (
        <Table bordered responsive>
            <tbody>
            <tr>
                <th scope="row" style={smallThTdStyle}>Calme</th>
                <td style={smallThTdStyle}>{currentFaceShot?.calm ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Colère</th>
                <td style={smallThTdStyle}>{currentFaceShot?.angry ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Tristesse</th>
                <td style={smallThTdStyle}>{currentFaceShot?.sad ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Confusion</th>
                <td style={smallThTdStyle}>{currentFaceShot?.confused ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Dégoût</th>
                <td style={smallThTdStyle}>{currentFaceShot?.disgusted ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Surprise</th>
                <td style={smallThTdStyle}>{currentFaceShot?.surprised ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Joie</th>
                <td style={smallThTdStyle}>{currentFaceShot?.happy ?? 'Aucune données'}</td>
            </tr>
            <tr>
                <th scope="row" style={smallThTdStyle}>Peur</th>
                <td style={smallThTdStyle}>{currentFaceShot?.fear ?? 'Aucune données'}</td>
            </tr>
            </tbody>
        </Table>
    )
}

export default EmotionTable