import React from 'react'

/**
 * A React component that displays audit data information.
 * 
 * @param {Object} props - The component props.
 * @param {Array} props.data - The array containing audit data objects.
 * 
 * This component renders details of an audit item, including preconisations,
 * step, citation, number of users, and argument, derived from the first 
 * element of the data array.
 */
const AuditDataItem = ({ data }) => {
    
    /*RENDER */
    return(
        <div className='mt-4'>
            <p className='text-bold'>{data?.preconisations}:</p>
            <ul className='recommendation-list'>
                <li>Etape: {data?.step}</li>
                <li>Citation: "{data?.citation}" ({data?.user})</li>
                <li>Nombre d'utilisateurs: {data?.users_number}</li>
                <li className='argument'>Argument: {data?.argument}</li>
            </ul>
        </div>
    )
}

export default AuditDataItem;