/**
 * React imports
*/
import React from 'react'
/**
 * Styles imports
 */
import './index.css';
/**
 * Function to render a spinner loader component.
 *
 * @return {JSX.Element} The spinner loader component.
 */
const SpinnerLoader = () => {
  return (
    <div className="spinner_container">
        <div className="spinner"></div>
    </div>
  )
}

export default SpinnerLoader;