import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());



export const getConnectedUser = async () => {
    const user = await axios.get(apiEndpoint + "/api/user", {
        headers: getAuthorization()
    })
    const { data } = user
    return data;
}
export const getSubClients = async () => {
    const subClients = await axios.get(apiEndpoint + "/api/subclients", {
        headers: getAuthorization()
    })
    const { data } = subClients
    return data;
}
export const getClients = async () => {
    const Clients = await axios.get(apiEndpoint + "/api/clients/list", {
        headers: getAuthorization()
    })
    const { data } = Clients
    return data;
}
export const getInfoStatus = async () => {
    const status = await axios.get(apiEndpoint + "/api/clientTester/submitted", {
        headers: getAuthorization()
    })
    const { data } = status
    return data;
}
export const getAdmins = async () => {
    const admins = await axios.get(apiEndpoint + "/api/admins", {
        headers: getAuthorization()
    })
    const { data } = admins
    return data;
}
export async function updateClientTester(info) {
    const response = await axios.post(apiEndpoint + "/api/clientTester/complete/profile", info, 
    { 
        headers: getAuthorization() 
    })
    const { data } = response
    return data;
}

export async function saveClientTester(client) {
    const response = await axios.post(apiEndpoint + "/api/clientTester/update", client, 
    { 
        headers: getAuthorization() 
    })
    const { data } = response
    return data
}

export async function saveClientTesterImage(image) {
    const response = await axios.post(apiEndpoint + "/api/clientTester/profile/photo", image, 
    { 
        headers: getAuthorization() 
    })
    const { data } = response
    return data
}

export async function updateClientTesterPassword(password) {
    const response = await axios.post(apiEndpoint + "/api/change/user/password", password, 
    { 
        headers: getAuthorization() 
    })
    const { data } = response
    return data
}

export async function updateClient(info) {
    const data = await axios.put(apiEndpoint + "/api/clients", { ...info }, {
        headers: getAuthorization()
    })
    return data
}

export const getTesters = async () => {
    const testers = await axios.get(apiEndpoint + "/api/testers/", {
        headers: getAuthorization()
    })
    const { data } = testers
    return data.reverse();
}

export const validateClient = async (id) => {
    const client = await axios.get(apiEndpoint + `/api/clients/validate/client`, {
        params: { client_id: +id },
        headers: getAuthorization()
    })
    const { data } = client
    return data;
}

export const validateTester = async (id) => {
    const tester = await axios.get(apiEndpoint + `/api/testers/validate`, {
        params: { tester_id: +id },
        headers: getAuthorization()
    })
    const { data } = tester
    return data;
}
export async function deleteAdmin(id) {
    const res = await axios.delete(apiEndpoint + `/api/admins/remove`, { params: { admin_id: +id }, headers: getAuthorization() });
    return res
}
export async function deleteSubClient(id) {
    const res = await axios.delete(apiEndpoint + `/api/subclients/remove`, { params: { subclient_id: +id }, headers: getAuthorization() });
    return res
}

export async function saveTester(user) {
    const res = await http.post(apiEndpoint + "/api/signup-tester", user);
    return res;
}
export async function saveClient(user) {
    const res = await http.post(apiEndpoint + "/api/signup-client", user);
    return res;
}

export async function saveAdmin(user) {
    const res = await http.post(apiEndpoint + "/api/admins", user, {
        headers: getAuthorization()
    });
    return res;
}
export async function saveSubClient(user) {
    const res = await http.post(apiEndpoint + "/api/subclients", user, {
        headers: getAuthorization()
    });
    return res;
}
export async function updatePwd(pwd) {
    const res = await http.post(apiEndpoint + "/api/changePassword", pwd, {
        headers: getAuthorization()
    });
    return res;
}
export async function submitNewPwd(pwd) {
    const res = await http.post(apiEndpoint + "/api/offline/reset/password", pwd);
    return res;
}
export async function help(help) {
    const res = await http.post(apiEndpoint + "/api/help", help, {
        headers: getAuthorization()
    });
    return res;
}

export async function getCurrentUser() {
    const response = await http.get(apiEndpoint + "/api/current/user", {
        headers: getAuthorization()
    });
    const { data } = response
    return data.response;
}

export async function checkMail(email) {
    const response = await http.post(apiEndpoint + "/api/verify/user/email", { email : email }, {
        headers: getAuthorization()
    });
    const { data } = response
    return data;
}


export async function checkMailModeHorsLigne(email) {
    const response = await http.post(apiEndpoint + "/api/verify/user/email", { email : email });
    const { data } = response
    return data;
}


export function getJwt() {
    return localStorage.getItem(tokenKey);
}
export function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}