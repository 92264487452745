import http from "./httpServices";
import { apiUrl } from "./../config.json";
import axios from "axios";

const apiEndpoint = apiUrl;
const tokenKey = "token";

http.setJwt(getJwt());

export const getClientScenarios = async () => {
    const scenarios = await axios.get(apiEndpoint + "/api/scenario/clients/list", {
        headers: getAuthorization()
    })
    const { data } = scenarios
    return data.response;
}
export const getAdminScenarios = async () => {
    const scenarios = await axios.get(apiEndpoint + "/api/scenario/admin/list", {
        headers: getAuthorization()
    })
    const { data } = scenarios
    return data;
}
export const getAllDataScenarios = async () => {
    const scenarios = await axios.get(apiEndpoint + "/api/scenario/client/progress/list", {
        headers: getAuthorization()
    })
    const { data } = scenarios
    return data;
}
export const getCount = async () => {
    const compteur = await axios.get(apiEndpoint + "/api/clients/compteurs", {
        headers: getAuthorization()
    })
    const { data } = compteur
    return data;
}
export const getCountTester = async () => {
    const compteur = await axios.get(apiEndpoint + "/api/testers/tester/compteur", {
        headers: getAuthorization()
    })
    const { data } = compteur
    return data;
}
export const scenarioNameVerification = async (scenarioName) => {
    const response = await axios.get(apiEndpoint + "/api/scenario/check/name", {
        params: { scenario_name: scenarioName },
        headers: getAuthorization()
    })
    const { data } = response
    return data;
}
export const playScenario = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/scenario/play`, {
        headers: getAuthorization(),
        params: { scenario_id: id }
    })
    const { data } = scenario
    return data;
}

export const setOnPlayOrOnPauseScenario = async (id) => {
    const step = await axios.post(apiEndpoint + `/api/scenario/tester/pause`, { scenario_id : id }, {
        headers: getAuthorization()
    })
    const { data } = step
    return data;
}

export const goTest = async (id) => {
    const test = await axios.get(apiEndpoint + `/api/tests/startTest/scenario`, {
        params: { id: id },
        headers: getAuthorization()
    })
    const { data } = test
    return data;
}

export const getQuestions = async (scenario_id, test_id) => {
    const test = await axios.get(apiEndpoint + `/api/scenario/questions/tester/list`, {
        params: { scenario_id, test_id },
        headers: getAuthorization()
    });
    const { data } = test
    return data;
}

export const getScenarioById = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/scenario/details/steps/details/list`, {
        headers: getAuthorization(),
        params: { scenario_id: id }
    })
    const { data } = scenario;
    return data;
}
export const resetUnfinishedTest = async (id) => {
    const test = await axios.get(apiEndpoint + `/api/tests/reset/terter/test`, {
        headers: getAuthorization(),
        params: { test_id: id }
    })
    const { data } = test;
    return data;
}
export const getStepById = async (id) => {
    const step = await axios.get(apiEndpoint + `/api/scenario/step/${id}`, {
        headers: getAuthorization()
    })
    const { data } = step
    return data;
}
export const duplicateScenario = async (id) => {
    const scenario = await axios.get(apiEndpoint + `/api/scenario/duplicate/${id}`, {
        headers: getAuthorization()
    })
    const { data } = scenario
    return data;
}
export async function saveScenario(scenario) {
    const res = await http.post(apiEndpoint + "/api/scenario/create", scenario, {
        headers: getAuthorization()
    });
    return res;
}
export const saveStep = async (idstep, payloads) => {
    const step = await axios.post(apiEndpoint + `/api/scenario/add/steps`, { idstep, payloads }, {
        headers: getAuthorization()
    })
    const { data } = step
    return data;
}
export const updateSteps = async (id, steps) => {
    const allSteps = await axios.put(apiEndpoint + `/api/scenario/${id}/steps`, steps, {
        headers: getAuthorization()
    })
    const { data } = allSteps
    return data;
}
export const editScenario = async (id, scena) => {
    const scenario = await axios.put(apiEndpoint + `/api/scenario/${id}`, scena, {
        headers: getAuthorization()
    })
    const { data } = scenario
    return data;
}

export const unpassedScenario = async (payload) => {
    const scenario = await axios.post(apiEndpoint + `/api/panels/free/scenario/tester/unpassed`, payload, {
        headers: getAuthorization()
    })
    const { data } = scenario
    return data;
}

export const passedTests = async (payload) => {
    const scenario = await axios.post(apiEndpoint + `/api/panels/free/scenario/tester/passed`, payload, {
        headers: getAuthorization()
    })
    const { data } = scenario
    return data;
}

export const closeScenario = async (id) => {
    const scenario = await axios.post(apiEndpoint + `/api/panels/free/scenario/tester/enclose`,  { scenario_id: id },
    {
        headers: getAuthorization(),
    })
    const { data } = scenario;
    return data;
}

export const sendAnswers = async (formData) => {
    const res = await axios.post(apiEndpoint + `/api/tests/submit/test/reponses`, formData, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}

export const sendVideo = (formData) => {
    const res = axios.post(apiEndpoint + `/api/tests/upload/video/command`, formData, {
        headers: getAuthorization()
    })
    return res;
}
export const addComment = async (id, comment) => {
    const res = await axios.put(apiEndpoint + `/api/scenario/step/answer/${id}`, comment, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}
export async function deleteScenario(id) {
    const res = await axios.delete(apiEndpoint + `/api/scenario/${id}`, { headers: getAuthorization() });
    return res
}

export async function deletePanel(panel_id) {
    const res = await axios.post(apiEndpoint + `/api/panels/free/delete`, panel_id, {
        headers: getAuthorization(),
    });
    const { data } = res
    return data;
}

export const editPanel = async (panel, panelId) => {
    const resPanel = await axios.post(apiEndpoint + `/api/panels/free/update`, {panel_id: panelId, panel: panel}, {
        headers: getAuthorization()
    })
    const { data } = resPanel
    return data;
}

export const ajouterTesterPanel = async (panel_id, clientTester) => {
    let newTester = {
        panel_id,
        name: clientTester.name,
        lastname: clientTester.lastname,
        email: clientTester.email,
    }
    const resPanel = await axios.post(apiEndpoint + `/api/panels/free/tester/add`, newTester, {
        headers: getAuthorization()
    })
    const { data } = resPanel
    return data;
}


export const replaceTester = async (panel_id, remplaceTester_id, clientTester) => {
    let newTester = {
        current_panel_id: panel_id,
        current_client_tester_id: remplaceTester_id,
        new_email : clientTester.email,
        new_name : clientTester.name,
        new_lastname : clientTester.lastname,
    }
    const resPanel = await axios.post(apiEndpoint + `/api/panels/free/tester/replace`, newTester , {
        headers: getAuthorization()
    })
    const { data } = resPanel
    return data;
}


export const getPanels = async () => {
    const panels = await axios.get(apiEndpoint + "/api/panels/client/list", {
        headers: getAuthorization()
    })
    const { data } = panels
    return data;
}

export const getPanelById = async (id) => {
    const panel = await axios.post(apiEndpoint + `/api/panels/free/scenario/tester/details`,  { panel_id: id },
    {
        headers: getAuthorization(),
    })
    const { data } = panel;
    return data;
}

export const detachementTesterFromPanel = async (panel_id, detachTester_id, detachedScenarios, clientTester) => {
    let newTester = {
        current_panel_id: panel_id,
        current_client_tester_id: detachTester_id,
        scenario_id: detachedScenarios,
        new_email : clientTester.email,
        new_name : clientTester.name,
        new_lastname : clientTester.lastname,
    }
    const panel = await axios.post(apiEndpoint + `/api/panels/free/scenario/tester/detachement`,  newTester,
    {
        headers: getAuthorization(),
    })
    const { data } = panel;
    return data;
}


export const savePanelExistant = async (scenarioId, panelId) => {
    const res = await axios.post(apiEndpoint + `/api/panels/free/scenario/update/assign`, { scenario_id: scenarioId, panel_id: panelId }, {
        headers: getAuthorization(),
    })
    const { data } = res
    return data;
}
export const savePanel = async (panel, scenarioId) => {
    const res = await axios.post(apiEndpoint + `/api/panels/free/scenario/create/assign`, { scenario_id: scenarioId, panel }, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}

export const editTesterFromPanel = async (tester) => {
    const res = await axios.post(apiEndpoint + `/api/panels/free/client/tester/update`, tester, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}

export const deleteTesterFromPanel = async (deleteTester) => {
    const res = await axios.post(apiEndpoint + `/api/panels/free/client/tester/delete`, deleteTester , {
        headers: getAuthorization()

    })
    const { data } = res
    return data;
}
export const checkTesterMail = async (testerMail) => {
    const res = await axios.post(apiEndpoint + `/api/panels/free/verify/mail/tester`, testerMail, {
        headers: getAuthorization()
    })
    const { data } = res
    return data;
}

export const getScenarioTestClientTester = async () => {
    const response = await axios.get(apiEndpoint + `/api/scenario/client/tester/list`, {
        headers: getAuthorization()
    })
    const { data } = response
    return data;
}

export const setTestAsInterrupted = async (test_id) => {
    const test = await axios.post(apiEndpoint + `/api/tests/tester/test/interrupt`, { test_id }, {
        headers: getAuthorization()
    })
    const { data } = test
    return data;
}

export const deleteTestsById = async (testsId) => {
    const response = await axios.post(apiEndpoint + `/api/panels/free/tests/remove`, { tests_id : testsId }, {
        headers: getAuthorization()
    })
    const { data } = response
    return data;
}

export const resentTesterCredentials = async (tester_id,scenario_id) => {
    const res = await axios.post(apiEndpoint + `/api/panels/free/resent/credentials`, {
        tester_id :tester_id,
        scenario_id : scenario_id
    } , {
        headers: getAuthorization()

    })
    const { data } = res
    return data;
}


export function getJwt() {
    return localStorage.getItem(tokenKey);
}
function getAuthorization() {
    return { Authorization: `Bearer ${getJwt()}` };
}