import React from 'react';


/**
 * Renders the StepChips component with the given labels and data.
 *
 * @param {string} labels - The labels to display.
 * @param {number} data - The data value to display.
 * @param {function} handleChangeEtape - The function to handle the change in etape.
 * @return {JSX.Element} The rendered StepChips component. 
 */
const StepChips = ({ labels, data, handleChangeEtape=()=>{},  labelClassName="", classNameContainer="" }) => {
  return (
    <div 
      className={classNameContainer}
      onClick={() => handleChangeEtape(labels)}
    >
      <div className={`${data && data > 0 ? "top" : data === 0 ? "neutre" : "flop"}`}> 
        { data }
      </div>
      <div className={labelClassName}> 
        { labels } 
      </div>
    </div>
  )
};

export default StepChips;