import React from "react";
import { Collapse } from 'react-collapse';
import commArrow from '../../../../assets/commArrow.svg'

function OpenComponentByTester(props) {
  const [show, setShow] = React.useState(false);
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 10, textAlign: 'start' }}>
          {/* <span className="top-component-fine-black-style"> */}
          <span className="question-text"> {props?.ids?.question}</span>
          {/* </span> */}
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div style={{
          minHeight: 50,
          borderRadius: 10,
          marginLeft: 15,
          marginTop: 20,
          padding: "10px",
          display: "flex",
          flexDirection: 'column',
          alignItems: "flex-start",
          textAlign: "start"
        }}>
          <span className="testeur-response-container-Partesteur">
            {props?.answer}
          </span>
        </div>
      </div>
      {
        props?.comment ?

          <div style={{
            display: 'flex',
            justifyContent: 'start',
            background: 'rgba(196, 196, 196, 0.1)',
            padding: '5px 10px',
            borderRadius: '10px',
            cursor: 'pointer',
            marginTop: '1em'
          }} onClick={() => setShow(!show)}>
            <span style={{
              fontFamily: 'sans-serif',
              color: '#00A359',
              fontSize: '18px',
              paddingLeft: 20
            }}
              className="mr-3">Commentaires</span>
            {
              !show ?
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_0" />
                :
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_180" />
            }
          </div>
          : null
      }
      <Collapse isOpened={!show}>
        {props?.comment &&
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em', marginTop: '1em' }}>
            <div className="testeur-response">
              <span className="testeur-response-container-Commentaires">
                {props?.comment}.
              </span>
            </div>
          </div>
        }
      </Collapse>
    </div>
  );
}

export default OpenComponentByTester;
