import React from 'react'
import DuplicateModal from './DuplicateModalForm'
import Modals from '../../../common/modal';
const RendreDuplicateModal = ({ show, onToggleShowDetails, onChangeScenario, currentScenario, scenarioLabel, onSubmit, loading, textError}) => {
    return (
        <Modals
            modalSize=""
            show={show}
            toggleShow={onToggleShowDetails}
            header='Dupliquer scenario'
        >
            <DuplicateModal
                confirmText='Confirmer'
                onSubmit={onSubmit}
                onchange={onChangeScenario}
                currentScenario={currentScenario}
                scenarioText={scenarioLabel}
                loading={loading}
                textError={textError}
            />
        </Modals>
    )
}

export default RendreDuplicateModal;