import React from 'react'
import Logo from "../../../../../assets/logo-vector.svg";



const ReportHeader = ({ title }) => {
    return (
        <div className="header mb-5">
            <div className="logoPDF-container">
                <img src={Logo} alt="insightData logo" />
            </div>
            <h3>{title}</h3>
        </div>
    )
}

export default ReportHeader