import React from 'react'

/**
 * A simple component that displays a title and a list of three points.
 * 
 * @param {object} props 
 * @param {array} props.points - an array of strings, the points to be displayed
 * @param {string} props.className - the class name to be given to the container of the component
 * @param {string} props.title - the title to be displayed
 */
const ListPoints = ({ points }) => {    

    if(!points) return null
    

    return (
        <ul>
            {Object.values(points).map((point, index) => (
                <li key={ index }>
                    { point }
                </li>
            ))}
        </ul>
    )
}

export default ListPoints