import React from 'react'
import Modals from '../../../common/modal'
import { Button } from 'reactstrap'

const RenderClotureModal = ({ showClose, onToggleCloseScenario, onCloseScenario }) => {
    return (
        <Modals
            header="Cloturer le scénario"
            show={showClose}
            modalSize="modal-sm"
            toggleShow={onToggleCloseScenario}
        >
            <p className="text-center">Voulez-vous vraiment cloturer ce scénario ?</p>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "2rem" }}>
                <Button className="btn-success" onClick={onCloseScenario}>Oui</Button>
                <Button onClick={onToggleCloseScenario}>Non</Button>
            </div>
        </Modals>
    )
}

export default RenderClotureModal