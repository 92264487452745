import React from "react";
import { Label } from "reactstrap";
import MaterialTable from "material-table";

const TableDashboard = (props) => {
    const { data, title, columns, actions, pageSize, pageSizeOptions  } = props
    return (
        <MaterialTable 
            style={{
                backgroundColor: "#fff", 
                border: "none ", 
                boxShadow: "none",
                borderCollapse: "separate", 
                borderSpacing: "0 1rem",
                width: "100%"
            }}
            title={ 
                    <Label  
                        style={{ 
                            fontSize: "2rem", 
                            color: "#00A359", 
                            fontWeight: "400", 
                            fontFamily:'roboto', 
                            lineHeight: "30px",
                            marginBottom: "1.8rem"
                        }}
                    >
                    {title}
                    </Label>
            }
            columns={  columns }
            data={ data }
            actions={ actions }
            localization={{
                body: {
                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'lignes',
                    labelRowsPerPage: 'lignes par page:',
                    firstAriaLabel: 'Première page',
                    firstTooltip: 'Première page',
                    previousAriaLabel: 'Page précédente',
                    previousTooltip: 'Page précédente',
                    nextAriaLabel: 'Page suivante',
                    nextTooltip: 'Page suivante',
                    lastAriaLabel: 'Dernière page',
                    lastTooltip: 'Dernière page'
                },
            }}
            options={{
                pageSize: pageSize,
                sorting: true,
                pageSizeOptions: pageSizeOptions ? pageSizeOptions : [5,10,20],
                actionsColumnIndex: -1,
                cellStyle: {
                    lineHeight: "1",
                    textAlign: "start",
                    fontSize: ".9rem",
                },
                headerStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                    backgroundColor:"#F3FBF7",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    fontFamily:'roboto',
                },
                searchFieldStyle: {
                    color: "#00a359",
                    MuiInputUnderline: "none",
                    marginBottom: "1.8rem"
                },
            }}
        />
    );
};
export default TableDashboard;
