import React, { useState, useEffect } from "react";
import BarChart from "./BarCharts";
import { Collapse } from 'react-collapse';
import commArrow from '../../../../assets/commArrow.svg'
// function ClosedBarComponent({ labels, question, data }) {
function ClosedBarComponent({ labels, question, data, index, comments, scale, etape }) {


  const [show, setShow] = useState(false);
  const [showSectionComments, setShowSectionComments] = useState(true);
  const [tabL, setTabL] = useState([]);
  const [tabD, setTabD] = useState([]);

  useEffect(() => {
    let minBorn = scale.questionChoices.minScale
    let maxBorn = scale.questionChoices.maxScale
    labels.sort((a, b) => a - b);
    let occTab = [];
    for (let i = minBorn; i <= maxBorn; i++) {
      occTab.push(0);
    }
    let j = 0;
    let occ = 0;
    let i = minBorn;

    if (minBorn === 0) {
      while (i <= maxBorn) {
        if (i === labels[j]) {
          occ = occ + data[j]
          occTab[labels[j]] = occ;
          j++;
        } else {
          occ = 0;
          i++;
        }
      }
    } else {
      while (i <= maxBorn) {
        if (i === labels[j]) {
          occ = occ + data[j]
          occTab[labels[j] - 1] = occ;
          j++;
        } else {
          occ = 0;
          i++;
        }
      }
    }
    let tabX = [];
    let tabD = [];
    for (let i = minBorn; i <= maxBorn; i++) {
      tabX.push(i);
    }
    j = 0;
    for (let i = minBorn; i <= maxBorn; i++) {
      if (i === labels[j]) {
        tabD.push(data[j])
        j++
      } else {
        tabD.push(0)
      }
    }
    Object.assign({}, tabX)
    Object.assign({}, tabD)
    setTabL(tabX);
    let test = false
    occTab.forEach(occ => {
      if (occ > 1) {
        test = true;
        return;
      }
    });
    if (test) {
      setTabD(occTab);
    } else {
      setTabD(tabD)
    }
   

    if (comments.length > 0) {
      let compte = 0;
      comments.map((d) => {
        if (d === null || d === undefined || d.trim() === "") {
          compte++;
        }
        if (compte === comments.length) {
          setShowSectionComments(false);
        }
      });
    }
  }, [comments, data, etape, labels, scale.questionChoices.maxScale, scale.questionChoices.minScale]);

  const somme = data?.reduce((a, b) => a + b, 0);


  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  return (
    <>
      {
        etape === index + 1 ?
          <div className="first-resume">
            <div className="question-resume-container">
              <div className="etape-question-container">
                <div style={{ flexDirection: "column", display: 'flex' }}>
                  <strong style={{ minWidth: '80px', textAlign: 'start' }}>Etape {index + 1} </strong>
                </div>
                <div style={{ paddingLeft: 20, textAlign: 'start' }}>
                  <span className="question-text">{question}</span>
                </div>
              </div>
              <div>
                <div className="Q-R-container">
                  <span className="question-container" style={{ marginLeft: 'auto', minWidth: '100px' }}>
                    Echelle de notation
                  </span>
                  <span className="response-container" style={{  minWidth: '100px' }} >
                    {data?.reduce(reducer)} réponses
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="chart-container-bar">
                <div className="chart-container-width">
                  <div className="text-chart-container">
                    <div>Trés</div>
                    <div>mauvaise</div>
                  </div>
                  <BarChart labels={tabL} data={tabD} somme={somme} />
                  <div className="text-chart-container">
                    <div>Trés</div>
                    <div>bonne</div>
                  </div>
                </div>
                {/* <span className="question-container" style={{ marginLeft: 'auto', minWidth: '200px' }}>
              Moyenne des notes : {Math.round((data?.reduce(reducer) / data?.length)) }
          </span>  */}
              </div>
            </div>

            {
              showSectionComments ?
                <div style={{
                  display: 'flex',
                  justifyContent: 'start-flex',
                  background: 'rgba(196, 196, 196, 0.1)',
                  padding: '5px 10px',
                  borderRadius: '10px',
                  cursor: 'pointer',
                  marginTop: '2em',
                }}
                  onClick={() => setShow(!show)}>
                  <span className="commentaire mr-3">Commentaires</span>
                  {
                    !show ?
                      <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_0" />
                      :
                      <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_180" />
                  }
                </div>
                :
                null
            }
            <Collapse isOpened={!show}>
              {comments && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em', margintop: '1em' }}>
                {comments.map((d, idx) => {
                  return (
                    <>
                      {
                        d &&
                        <div className="testeur-response">
                          <span className="tester_1">Testeur {idx + 1}  </span>
                          <span className="testeur-response-container-Commentaires">
                            {d}.
                          </span>
                        </div>
                      }
                    </>
                  );
                })}
              </div>}
            </Collapse>
          </div>
          : null
      }
    </>
  );
}

export default ClosedBarComponent;
