import React, { useState, useEffect } from "react";
import { Collapse } from 'react-collapse';
import commArrow from '../../../../assets/commArrow.svg'

function OpenComponent({ question, data = [], labels, index, comments }) {


  const [show, setShow] = useState(false);
  const [showSectionComments, setShowSectionComments] = useState(true);

  useEffect(() => {
    if (comments.length > 0) {
      let compte = 0;
      comments.map((d) => {
        if (d === null || d === undefined || d.trim() === "") {
          compte++;
        }
        if (compte === comments.length) {
          setShowSectionComments(false);
        }
      });
    }
  }, [comments]);




  return (
    <div className="first-resume">
      <div className="first-resume-contianer">
        <div className="first-resume-top">
          <div className="question-resume-container">
            <div className="etape-question-container">
              <div style={{ flexDirection: "column", display: 'flex' }}>
                <strong style={{ minWidth: '80px', textAlign: 'start' }}>Etape {index + 1}  </strong>
              </div>
              <div style={{ paddingLeft: 20, textAlign: 'start', marginBottom: 20 }}>
                <span className="question-text">{question}</span>
              </div>
            </div>
          </div>

          <div className="reponses-section">
            <div className="reponses-text"> Réponses </div>
            <div className="reponses-container">

              {labels.map((d, idx) => {
                return (
                  <div className="testeur-response-open">
                    <span className="tester_1">
                      Testeur {idx + 1}
                    </span>
                    <span className="testeur-response-container">
                      {d}.
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="Q-R-container" style={{ marginRight: "1rem" }}>
          <span className="question-container" style={{ marginLeft: 'auto', minWidth: '100px' }}>
            Question ouverte
          </span>
          <span className="response-container" style={{  minWidth: '100px' }} >
            {labels && labels.length} réponses
          </span>
        </div>
      </div>
      {
        showSectionComments ?
          <div style={{
            display: 'flex',
            justifyContent: 'start-flex',
            background: 'rgba(196, 196, 196, 0.1)',
            padding: '5px 10px',
            borderRadius: '10px',
            cursor: 'pointer',
            marginTop: '2em',
          }}
            onClick={() => setShow(!show)}>
            <span className="commentaire mr-3">Commentaires</span>
            {
              !show ?
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_0" />
                :
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_180" />
            }
          </div>
          :
          null
      }
      <Collapse isOpened={show} >
        {comments && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em', marginTop: '1em' }}>
          {comments.map((d, idx) => {
            return (
              <>
                {d &&
                  <div className="testeur-response">
                    <span className="tester_1">Testeur {idx + 1}  </span>
                    <span className="testeur-response-container-Commentaires">
                      {d}.
                    </span>
                  </div>
                }
              </>);
          })}
        </div>}
      </Collapse>
    </div>
  );
}

export default OpenComponent;
