import React, { useState } from "react";
import InputField from "../../common/InputField";
import UploadFile from "./rapports/components/UploadFile";
import AuditUXFlashReport from "./rapports/AuditUXFlashReport";
import SpinnerLoader from "../../common/loaders/SpinnerLoader";
import { AvForm } from "availity-reactstrap-validation";
import LoaderButton from "../../common/loaders/LoaderButton";
import { generateAuditUXFlash } from "../../../services/analyzeServices";
import { toast } from "react-toastify";

/**
 * A React component that displays a form for generating an audit UX report.
 * It includes input fields for client name and file upload functionality.
 * Displays a loading spinner while the report is being generated.
 * Shows the generated audit UX report once the form data is available.
 * 
 * @returns {JSX.Element} The rendered component.
*/
const AuditUXFlashForm = () => {
    /* HOOKS */
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);
    

    /**
     * Handles the form submission event.
     * If the form is valid, it calls the `generateAuditUXFlash` service to generate the report.
     * If the report is successfully generated, it displays a success message and sets the `formData` state to the submitted form data
     * and sets the `response` state to the response from the service.
     * If there is an error, it displays an error message.
     * The component also displays a spinner while the report is being generated.
    */
    const handleSubmitFrom = async (event, errors, values) => {

        event.preventDefault();            
        setFormData(null);
        if (errors?.length === 0) {
            try {
                setLoading(true);     
                const response = await generateAuditUXFlash(selectedFile);
                if(response?.data?.header?.code !== 200){
                    toast.error("Le Fichier est invalide, veuillez en choisir un autre");
                }else{
                    setFormData({
                        clientName:values?.clientName,
                        data:response?.data?.response
                    });
                }
            } catch (err) {
                console.log(err);
            } 

            setLoading(false);
        }else{
            toast.error("Veuillez remplir tous les champs");
        }
    }
    

    /*RENDER */
    return (
        <div>
            <div>
                <span className="welcome-text_client-tester">Rapport de audit UX</span>
            </div>
            <div>
            <AvForm onSubmit={handleSubmitFrom}>
                <div className="form-audit-container">
                    <InputField type ="text" label="Nom du client" name="clientName" required={true} />
                    <UploadFile 
                        setSelectedFile={setSelectedFile}
                        setIsFileUploaded= {setIsFileUploaded}
                        isFileUploaded={isFileUploaded}
                    />
                </div>
                <LoaderButton className="btn-success my-4" loading={loading} name="Générer le rapport" />
            </AvForm>
            </div>
            { loading && <SpinnerLoader />}
            {
                formData && (
                    <AuditUXFlashReport formData={formData} />
                )
            }
        </div>
    );
};

export default AuditUXFlashForm;
