import {
    SUCCESS_GET_SCENARIOS, FAILED_GET_SCENARIOS, FAILED_GET_PANELS,
    SUCCESS_GET_PANELS, SUCCESS_GET_COUNT, FAILED_GET_COUNT,SAVE_LIST,DELETE_LIST,UPDATE_LIST,SELECTED_SCENARIO
} from "../typeActions/scenario"

const INITIAL_STATE = {
    scenario: {},
    scenarios: [],
    panels: [],
    count:{},
    steps:[],
    error: "",
    errorPanels: "",
    errorCount: "",
    errorScenaiors : "",
    selectedScenario : {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUCCESS_GET_SCENARIOS:
            return {
                ...state,
                scenarios: action.scenarios,
                error: ""
            };
        case SUCCESS_GET_COUNT:
            return {
                ...state,
                count: action.count,
                error: ""
            };
        case SUCCESS_GET_PANELS:
            return {
                ...state,
                panels: action.panels,
                error: ""
            };
        case SAVE_LIST:
            return {
                ...state,
                steps: action.steps,
                error: ""
            };
        case UPDATE_LIST:
            return {
                ...state,
                steps: action.steps,
                error: ""
            };
        case DELETE_LIST:
            return {
                ...state,
                steps: action.steps,
                error: ""
            };
        case FAILED_GET_PANELS:
            return {
                ...state,
                errorPanels: action.payload
            };
        case FAILED_GET_COUNT:
            return {
                ...state,
                error: action.payload
            };
        case FAILED_GET_SCENARIOS:
            return {
                ...state,
                error: action.payload
            };
        case SELECTED_SCENARIO:
            return {
                ...state,
                selectedScenario: action.scenario
            };
        default:
            return state;
    }
};
