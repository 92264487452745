import React from "react";
import MaterialTable from "material-table";

const TableAdmin = (props) => {

    const { data,columns, actions} = props
    
    return (
        <MaterialTable
            style={{
                backgroundColor: "white", 
                border: "none ", 
                boxShadow: "none",
                borderCollapse: "separate", 
                borderSpacing: "0 1rem",
                width: "100%",
            }}
            columns={
                columns
            }
            data={
                data
            }
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'lignes',
                    labelRowsPerPage: 'lignes par page:',
                    firstAriaLabel: 'Première page',
                    firstTooltip: 'Première page',
                    previousAriaLabel: 'Page précédente',
                    previousTooltip: 'Page précédente',
                    nextAriaLabel: 'Page suivante',
                    nextTooltip: 'Page suivante',
                    lastAriaLabel: 'Dernière page',
                    lastTooltip: 'Dernière page'
                },
            }}
        
            options={{
                pageSize:5,
                sorting: true,
                pageSizeOptions:[5, 10],
                actionsColumnIndex: -1,
                cellStyle: {
                    lineHeight: "1",
                    textAlign: "start",
                    fontSize: ".9rem"
                },
                headerStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                    backgroundColor:"#fff",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    fontFamily:'roboto',
                },
                search: false,
                showTitle: false,
      }}
    />
  );
};
export default TableAdmin;
