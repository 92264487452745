/**
 * React imports
*/
import React, { useCallback, useState } from "react";

/**
 * reactstrap imports
*/
import { Label } from "reactstrap";

/**
 * components imports
*/
import SkeletonHeader from "../skeletonHeader.jsx";

/**
 * Images imports
 */
import Eye from '../../../../assets/eye.svg';
import HeaderScoreModal from "./HeaderScoreModal.jsx";
import { downloadExcel } from "../../../../services/statServices.js";
import { toast } from "react-toastify";
import Download from "../../../../assets/dwnld.png";

/**
 * Renders the right side of the header component.
 *
 * @param {Object} props - The props of the component.
 * @param {boolean} props.skeleton - Indicates if the component is in skeleton state.
 * @param {Array} props.header - The header data.
 * @param {number} props.nbrTester - The number of testers.
 *
 * @return {JSX.Element} The JSX element representing the right side of the header component.
 */
const ScenarioDetails = ({ skeleton, header, nbrTester, analyseObj, excel, arrayOftesters, renderType }) => {

    /* HOOKS */
    const [open, setOpen] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    /* FUNCTIONS */

    /**
     * Handles the opening of the modal.
     */
    const handleOpenModal = () => {
        setOpen((prevState) => !prevState);
    }
    /* HOOKS */

    /**
     * Download all data in excel format
     * @returns {Promise<void>}
     */
    const handleDownloadAllData = async () => {
        let url;
        try {
            setIsDownloading(true);
            const res = await downloadExcel(header.id, arrayOftesters);
            if (res?.header?.code === 400) {
                toast.error(res?.header?.message);
                return;
            }

            url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `allData_${header?.title || "data"}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            toast.success("Fichier téléchargé avec succès");
        } catch (error) {
            console.error("ERROR", error);
        } finally {
            setIsDownloading(false);
            URL.revokeObjectURL(url);
        }
    };    
    
    /**
     * Renders the header based on the renderType prop.
     * If renderType is 'analyse', renders the score modal and the download button.
     * If renderType is 'allData', renders the download button.
     * If renderType is neither of the above, returns null.
     * @return {JSX.Element} The JSX element representing the header component.
     */
    const onHeaderRender = useCallback(() => {    
        if(renderType === 'analyse'){ 
            return(
                <>
                    <div className='anaylyse-etape-img'>
                    <Label className="header_label">Résumé</Label>
                    {
                        skeleton ?  <SkeletonHeader /> : header?.score ?
                        <img src={Eye} alt="2M-advisory" style={{ width: '35px', cursor: 'pointer' }} onClick={handleOpenModal} />
                        : 
                        null
                    }
                    </div>
                    {
                        open &&  <HeaderScoreModal open={open} handleOpenModal={handleOpenModal} header={header} analyseObj={analyseObj} />
                    }
                </>
            )
        }else if(renderType == 'allData'){
            return(
                <>
                {
                    excel && (
                        <div className='anaylyse-etape'>
                            <Label className="header_label">Télécharger</Label>
                            {
                                skeleton ?  
                                    <SkeletonHeader /> 
                                : 
                                    header?.testers &&
                                        <span className='header_span'>
                                            {
                                                isDownloading ? 
                                                <i className="fa fa-spinner fa-pulse" aria-hidden="true"></i>
                                                : 
                                                <img src={Download} alt="" style={{ width: "1.5rem", cursor: "pointer" }} onClick={handleDownloadAllData} />
                                            }
                                        </span>
                            }
                        </div>
                    )
                }
                </>
            )
        }else{
            return null
        }
    }, [renderType, skeleton, header, open, handleOpenModal, analyseObj, excel, handleDownloadAllData, isDownloading, arrayOftesters]);

    /* RENDER */
    return(
        <div className='anaylyse_header-etapes'>
            <div className='anaylyse-etape'>
                <Label className="header_label">Etapes</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.steps ? <span className='header_span'>{header?.steps} </span> : null
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Testeurs</Label>
                {
                    skeleton ? 
                        <SkeletonHeader /> 
                    : 
                        header?.testers ? 
                            <>
                                {
                                    nbrTester === header?.testers ?
                                        <span className='header_span completTest'>
                                            {`${nbrTester}/${header?.testers}`}
                                        </span>
                                    :
                                        <span className='header_span encoursTest'>
                                            {`${nbrTester}/${header?.testers}`}
                                        </span>
                                }
                            </>
                        :
                            ""
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Panel</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.type ? <span className='header_span'>{header?.type === 'client' ? 'Client' : header?.type}</span> : null
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label" data-tip='Le score peut aller de -1 à 1'>Score</Label>
                {
                    skeleton ? <SkeletonHeader /> : header?.score ? 
                            <span className='header_span' style={{ color: header?.score >= 0 ? 'green' : 'red' }}>{header?.score}</span>
                        : 
                            null
                }
            </div>
            <div className='anaylyse-etape'>
                <Label className="header_label">Durée</Label>
                {
                    skeleton ? 
                        <SkeletonHeader />  
                    : 
                        header?.duration ? 
                            <span className='header_span'>
                                {`${Math.floor(header?.duration / 60) > 0 ? Math.floor(header?.duration / 60) + 'min :' : ''} ${Math.floor(header?.duration) % 60} sec`}
                            </span> 
                        : 
                            null
                }
            </div>
            {onHeaderRender()}
        </div>
    );
}

export default ScenarioDetails;