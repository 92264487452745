import React from 'react'


/**
 * A React component that renders a list of recommendations.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.recommendations - An object containing key-value pairs
 * representing recommendations, where each key is a recommendation title and 
 * the value is the recommendation description.
 *
 * @returns {JSX.Element|null} A <ul> element with a list of recommendations, 
 * or null if no recommendations are provided.
 */
const ListRecommendations = ({ recommendations }) => {

    const convertToRoman = (num) => {
        const romanNumerals = ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X"];
        return romanNumerals[num - 1] || num;
    }

    /*RENDER */
    if (!recommendations) return null;
    return (
        <ul>
            {Object.entries(recommendations).map(([key, value], index) => (
                <li key={ index }>
                    <div>
                        <span className="roman-number">{convertToRoman(index + 1)}.</span>
                    </div>
                    <div>
                        <span className='text-bold'> { key } :</span>
                        <span> { value } </span>
                    </div>
                </li>
            ))}
        </ul>
    );
}

export default ListRecommendations
