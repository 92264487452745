/**
 * @file RightStat.jsx
 */

/**
 * React imports
 * @see https://reactjs.org/docs/getting-started.html
 */
import React,{ useEffect, useState } from "react";


/**
 * Reactstrap imports
 * @see https://reactstrap.github.io/
 */
import { Col } from "reactstrap";

/**
 * React-redux imports
 * @see https://react-redux.js.org/
 */
import { connect, useSelector } from 'react-redux';
import { onGetScenarios, onGetCount } from "../../../actions/scenarioActions";
import { onGetSubClients } from '../../../actions/userActions'



/*******************************************************************/
/*                        RightStat Component                      */
/******************************************************************/
const RightStat = ({handleScenarioC, handleScenarioL}) => {

    /**************************************************************/
    /*                           HOOKS                            */
    /**************************************************************/
    const { scenarios } = useSelector((state) => state.scenario);
    const { subClients } = useSelector((state) => state.user)
    const [countAlancer, setCountAlancer] = useState(0);
    const [countEnCours, setCountEnCours] = useState(0);
    const [countSubClient, setCountSubClient] = useState(0);



    /**
     * @function onGetSubClients
     * @description Get subClients
     * call onGetSubClients action
     * count subClients
     * count scenarios a lancer
     * count scenarios en cours
     */
    useEffect(() =>{
        //call onGetSubClients action
        onGetSubClients();
        //variable count
        let countEnCours = 0;
        let countAlancer = 0;
        let countC = 1;


        //count scenarios a lancer and en cours
        scenarios?.map(scenario => {
            if(scenario.etat === 3){
                countEnCours = countEnCours + 1
            }
            if(scenario.etat === 2){
                countAlancer = countAlancer + 1
            }
            return countEnCours && countAlancer
        })

        //count subClients
        if(subClients.lenght > 0){
            subClients.map(subClient => {
                if(subClient){
                    countC = countC + 1
                }
                return countC
            })
        }
        //set counts
        setCountAlancer(countAlancer)
        setCountEnCours(countEnCours)
        setCountSubClient(countC)

    },[scenarios,  subClients])


    /**************************************************************/
    /*                         RENDERING                          */
    /**************************************************************/

    return(
        <div className='scenario__info-dashboard'>
            <Col className="col__style-dashboard">
                <span className='text-square mb-3'>Licence</span>
                <span className='essai mb-1'>Essai</span>
                <span className='modifier'>Changer</span>
            </Col>

            <Col className="col__style-dashboard">
                <span className='text-square mb-4'>Utilisateurs</span>
                    <span className='square4'>{countSubClient}</span>
            </Col>
            {
                handleScenarioL?
                    <Col className="col__style-dashboard pointer" onClick={handleScenarioL}
                        data-tip="cliquer pour voir les scénarios lancés en premier"
                        data-background-color="#F3FBF7"
                        data-text-color='#000'
                        data-multiline={true}
                        data-type='info'
                    >
                        <span className='text-square mb-3 px-5'>Scénarios a lancer</span>
                        {
                            countAlancer ?
                            <span className='square4'>{countAlancer}</span>
                            :
                            <span className='square2'>{0}</span>
                        }
                    </Col>
                    :
                    <Col className="col__style-dashboard">
                        <span className='text-square mb-3 px-5'>Scénarios a lancer</span>
                        {
                            countAlancer ?
                            <span className='square4'>{countAlancer}</span>
                            :
                            <span className='square2'>{0}</span>
                        }
                    </Col>
            }
            {
                handleScenarioC?
                <Col className="col__style-dashboard pointer"  onClick={handleScenarioC} 
                        data-tip="cliquer pour voir les scénarios en cours et terminer en premier"
                        data-background-color="#F3FBF7"
                        data-text-color='#000'
                        data-multiline={true}
                        data-type='info'
                    >
                        <span className='text-square mb-3 px-5'>Scénarios ouverts</span>
                        {
                            countEnCours ?
                            <span className='square4'>{countEnCours}</span>
                            :
                            <span className='square2'>{0}</span>
                        }
                    </Col>
                    :
                    <Col className="col__style-dashboard">
                    <span className='text-square mb-3 px-5'>Scénarios ouverts</span>
                    {
                        countEnCours ?
                        <span className='square4'>{countEnCours}</span>
                        :
                        <span className='square2'>{0}</span>
                    }
                </Col>
            }
        </div>
    );
}

/**
 * Redux mapping state to props 
 */
const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

/**
 * Export component RightStat with redux
 * @exports RightStat
 * 
 */
export default connect(
    mapStateToProps,
    { onGetScenarios, onGetCount, onGetSubClients }
)(RightStat);