/**
 * React imports
*/
import React, { useMemo } from "react";

/**
 * reactstrap imports
*/
import { Input } from "reactstrap";

/**
 * Redux imports
 */
import  { useSelector } from 'react-redux'

/**
 * Render the left side of the header.
 *
 * @param {Array} scenarios - The list of scenarios.
 * @param {string} title - The title of the header.
 * @param {function} onChangeScenario - The function to handle scenario change.
 * @return {JSX.Element} The rendered header left side.
 */
const ScenariosDropList = ({scenarios, onChangeScenario, renderType}) => {

    /* HOOKS */
    const { selectedScenario } = useSelector((state) => state.scenario);

    
    const scenarioOptions = useMemo(() => scenarios.map((scenario, id) => (
        <option key={id} value={renderType ==="allData" || renderType === "panel" ?scenario.id : id} selected={selectedScenario.id === scenario.id}>
            {scenario.title}
        </option>
    )), [scenarios, selectedScenario.id, renderType]);
    

    /*RENDER */
    return (
        <div>
        <Input type="select" name="select" className='btn_filter_select_scena-header' id="exampleSelect" onChange={onChangeScenario}>
            <option defaultValue="Sélectionner un scénario" defaultChecked selected disabled>Sélectionner un scénario</option>
            { scenarioOptions }
        </Input>
    </div>
    )
}


export default ScenariosDropList;