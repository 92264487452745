import {
    EMAIL_CHANGED,
    PASSWORD_CHANGED,
    LOGOUT, FAILED_LOGIN,
    SUCCESS_LOGIN,SUCCESS_OPEN_POPUP,
    TAB_DASHBOARD_ITEM_CHANGED,TAB_DASHBOARD_MENU_CHANGED, SUCCESS_OPEN_WINDOW,
    LNG_CHANGED, SUCCESS_LOGIN_TESTER2, SUCCESS_LOGIN_TESTER1,FAILED_FORGET_PASSWORD,SUCCESS_FORGET_PASSWORD,COLLAPSE_DASHBOARD
} from "./../typeActions/auth";

const INITIAL_STATE = {
    isAuthenticated: false,
    username: "",
    password: "",
    user: {},
    lng: 'Fr',
    tab: "Tableau de Bord",
    menu:'',
    currentUserId: "",
    loading: true,
    openWindow: false,
    openPopup: false,
    connected: "",
    error: "",
    forgetPassword : {},
    collapseDashboard : false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LNG_CHANGED:
            return {
                ...state,
                lng: action.payload
            }
        case TAB_DASHBOARD_ITEM_CHANGED:
            return {
                ...state,
                tab: action.payload
            }
        case TAB_DASHBOARD_MENU_CHANGED:
            return {
                ...state,
                menu: action.payload
            }
        case EMAIL_CHANGED:
            return {
                ...state,
                username: action.payload,
                error: ""
            };
        case PASSWORD_CHANGED:
            return {
                ...state,
                password: action.payload,
                error: ""
            };
        case LOGOUT:
            return {
                isAuthenticated: false,
                username: "",
                password: "",
                user: {},
                lng: 'Fr',
                currentUserId: "",
                loading: true,
                error: ""
            };
        case SUCCESS_LOGIN:
            return {
                ...state,
                username: "",
                password: "",
                isAuthenticated: true,
                user: action.user,
                currentUserId: action.user.user_id,
                loading: false
            };
        case SUCCESS_LOGIN_TESTER1:
            return {
                ...state,

                connected: true,

            };
        case SUCCESS_LOGIN_TESTER2:
            return {
                ...state,

                connected: state.connected,

            };
        case SUCCESS_OPEN_WINDOW:
            return {
                ...state,

                openWindow: true,

            };
        case SUCCESS_OPEN_POPUP:
            return {
                ...state,

                openPopup: !state.openPopup,

            };
        case FAILED_LOGIN:
            return {
                ...state,
                password: "",
                error: action.payload
            };
        case SUCCESS_FORGET_PASSWORD:
            return {
                ...state,
                forgetPassword: action.payload,
            };
        case FAILED_FORGET_PASSWORD:
            return {
                ...state,
                error: action.payload
            };
        case COLLAPSE_DASHBOARD:
            return {
                ...state,
                collapseDashboard: action.payload
            };
            
        default:
            return state;
    }
};
