import React from 'react';


const ConfimInscription = () => {

    return (
        <>
            <div className="col-md-12">
                <div className='modal_inscription_admin'>Le compte testeur a été bien activé</div>
            </div>
        </>
        
    )
}
export default ConfimInscription

