import React, { useState, useEffect } from "react";
import PieChart from "./PieChart";
import { Collapse } from 'react-collapse';
import commArrow from '../../../../assets/commArrow.svg'



function PieComponent({ question, labels, data, index, comments }) {

  // const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const [show, setShow] = useState(false);
  const [showSectionComments, setShowSectionComments] = useState(true);
  const [backgroundColor, setBackgroundColor] = useState(["#6c757d", "#00A359", "#c4c2c2", "#00FF00", "#606060"]);
  useEffect(() => {
    if (comments.length > 0) {
      let compte = 0;
      comments.map((d) => {
        if (d === null || d === undefined || d.trim() === "") {
          compte++;
        }

        if (compte === comments.length) {
          setShowSectionComments(false);
        }
      });
    }
    const backgroundColor = ["#00A359", "#6c757d", "#c4c2c2", "#00FF00", "#606060"];
    // si le premier label est "Oui" alors envoie backgroundColor
    if (labels[0].toLowerCase() === "oui") {
      setBackgroundColor(backgroundColor);
    }

  }, [comments]);

  const somme = data.reduce((a, b) => a + b, 0);


  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div className="etape-question-container">
          <div style={{ flexDirection: "column", display: 'flex' }}>
            <strong style={{ minWidth: '80px', textAlign: 'start' }}>Etape {index + 1}  </strong>
          </div>
          <div style={{ paddingLeft: 20, textAlign: 'start' }}>
            <span className="question-text">{question}</span>
          </div>
        </div>
        <div>
          <div className="Q-R-container">
            <span className="question-container" style={{ marginLeft: 'auto', minWidth: '100px' }}>
              Question fermée
            </span>
            <span className="response-container" style={{  minWidth: '100px' }} >
              {somme} réponses
            </span>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="pie-chart-container">
          <PieChart {...{ data, labels, somme, backgroundColor }} />
        </div>
      </div>
      {
        showSectionComments ?
          <div style={{
            display: 'flex', justifyContent: 'flex-start',
            background: 'rgba(196, 196, 196, 0.1)',
            padding: '5px 10px',
            borderRadius: '10px',
            cursor: 'pointer',
            // marginTop: '2em',
          }} onClick={() => setShow(!show)}>
            <span className="commentaire mr-3">Commentaires</span>
            {
              !show ?
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_0" />
                :
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_180" />
            }

          </div>
          : null
      }
      <Collapse isOpened={!show}>
        {comments && <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1em', marginTop: '1em' }}>

          {comments.map((d, idx) => {
            return (
              <>
                {d && <div className="testeur-response">
                  <span className="tester_1">
                    Testeur {idx + 1}
                  </span>
                  <span className="testeur-response-container-Commentaires">
                    {d}.
                  </span>
                </div>}
              </>);
          })}
        </div>}
      </Collapse>
    </div>

  );
}

export default PieComponent;
