import React from 'react'
import Favorite from './favorite'

/**
 * DashboardHeader is a component that displays a header bar
 * with a Favorite component and other children components
 * passed as props.
 *
 * @param {React.ReactNode} children - Children components
 * to be displayed in the header bar.
 */
const DashboardHeader = ({ children }) => {
    return (
        <div className="col-top-component-container">
            <Favorite />
            { children }
        </div>
    )
}

export default DashboardHeader