/**
 * React import
 */
import React from 'react';

/**
 * Decode a base64 image into a URL image.
 *
 * @param {object} base64Image - the base64 representation of the image
 * @param {...any} propos - additional properties for the image element
 * @return {JSX.Element} the image element with the specified URL and additional properties
*/
const ImageDecoder = ({ base64Image, ...propos }) => {

    // Convertir l'image base64 en URL d'image
    const imageUrl = `data:image/png;base64,${base64Image}`;

    /* RENDER */
    return (
        <img src={imageUrl} {...propos} />
    );
}

export default ImageDecoder;