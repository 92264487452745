/**
 * @file duplicateModal.jsx
 * @description This file contains the modal for duplicate a client.
 */

/**
 * @import React
 */
import React from 'react';

/**
 * @import reactstrap
 */
import {Button, Label, Col} from 'reactstrap';

/**
 * @import availity-reactstrap-validation
 */
import { AvForm, AvField } from 'availity-reactstrap-validation';

const DuplicateModalForm = ({confirmText, scenarioText, onSubmit, currentScenario, onchange, loading, textError}) => {

    return (
        <>
            <AvForm onValidSubmit={onSubmit}>
                <Col md='12'>
                    <div className='modal__form'>         
                        <Label id="label__style__signup">{scenarioText.oldName}</Label>
                        <AvField name="lastname" value={currentScenario.oldName}
                            type="text"
                            id='lastName'
                            disabled
                        />
                        <Label id="label__style__signup">{scenarioText.newName}</Label>
                        <AvField name="name" value={currentScenario.newName}
                        type="text"
                            onChange={onchange}
                            id='firstName'
                            validate={{
                                maxLength: { value: 50 },
                                required: { value: true, errorMessage: "Ce champ est obligatoire" },
                                pattern: { value: "[a-zA-Z0-9]$", errorMessage: "les caractéres speciaux ne sont pas autorisé" }
                            }}
                        />
                        <span style={{color : "red" , fontSize : "12px",display : "flex", justifyContent : "center"}}>{textError}</span>
                        <div className="btn_modal_ctn">
                            <Button type="submit" className="btn__form__modal" disabled={loading}
                            >
                                {loading && <i className="fa fa-refresh fa-spin mr-2" />}{confirmText}</Button>
                        </div>
                    </div>
                </Col>
            </AvForm>
        </>
    )
}
export default DuplicateModalForm;

