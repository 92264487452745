import React from 'react'
import Modals from '../../../common/modal';
import { Button } from 'reactstrap';

const RendreDeleteModal = ({ showDeleteModal, onToggleDeleteScenario, onDeleteScenario }) => {
    return (
        <Modals
            header="Supprimer un scénario"
            show={showDeleteModal}
            modalSize="modal-sm"
            toggleShow={onToggleDeleteScenario}
        >
            <p className="text-center">Voulez-vous vraiment supprimer ce scénario ?</p>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "2rem" }}>
                <Button className="btn-success" onClick={onDeleteScenario}>Oui</Button>
                <Button onClick={onToggleDeleteScenario}>Non</Button>
            </div>
        </Modals>
    )
}

export default RendreDeleteModal;