import React, { useState, useEffect, useCallback } from "react";
import { connect } from 'react-redux';
import FirstResume from "./rawComponents/FirstResume";
import { getAllDataScenarios } from "../../../services/scenarioServices";
import { getScenarioDetails, getScenarioStat } from "../../../services/statServices";
import { toast } from "react-toastify";
import SpinnerLoader from "../../common/loaders/SpinnerLoader";
import DashboardHeader from "../../common/DashboardHeader";
import NoDataSelected from "../../common/NoDataSelected";
import HeaderBar from "../../analyzes/byStep/header/AnalyseHeader";
import './rowData.css'


function RawData(props) {

  let scen = [];
  if (props?.scenario?.scenarios !== null || props?.scenario?.scenarios !== undefined || props?.scenario?.scenarios?.length > 0) {
    scen = props.scenario?.scenarios?.filter(scen =>
      scen.id === props?.scenario?.selectedScenario.id
    )
  }

  const [choice, setChoice] = useState("");
  const [_id, setId] = useState(props?.scenario?.selectedScenario.id && props?.scenario?.selectedScenario.id);
  const [scenarios, setScenarios] = useState([]);
  const [headerPayload, setHeaderPayload] = useState([]);
  const [scale, setScale] = useState(scen ? scen[0]?.steps[0] : {});
  const [first, setFirst] = useState(true);
  const [loading, setLoading] = useState(false);


  const getScenarios = useCallback(async() => {
    try {
      await getAllDataScenarios().then(response => setScenarios(response?.response))
    } catch (error) {
      console.log("Error",error)
    }
  }, []);

  const getScenario = useCallback(async () => {
    try {
      setLoading(true)
      if (_id) {
        await getScenarioDetails(_id).then(async (response) => {
          if (response.header.code !== 200) {
            return toast.error(response.header.message)
          } else {
            setHeaderPayload(response?.response);
            await getScenarioStat(response?.response.id).then(res => {
              let arrayOftesters = []
              let data = res.response
              Object.keys(data).map((key, index) => {
                const object = data[key];
                arrayOftesters = object?.answers?.map((val) => (val?.clientTester?.id));
              })
            })
          }
        })
      }  
    } catch (error) {
      console.log("Error",error)
    }finally {
      setLoading(false);
    }
  }, [_id]);


  const onChangeScenario = (e) => {    
    let scen;
    if (isNaN(e)) {
      setId(e.target.value);
      scen = props.scenario.scenarios.filter(scen =>
        scen.id === +e.target.value
      );
    } else {
      setId(+e);
      scen = props.scenario.scenarios.filter(scen =>
        scen.id === +e
      );
    }

    scen[0]?.steps.map((step) => {
      if (step.type === "scale")
        setScale(step);
    })
    setChoice("scenario");
  }

  useEffect(() => {
    let isMounted = true;
    try{
      if (isMounted) {
        getScenarios();
      }
      if (props.scenario.selectedScenario && first) {
        if (props.scenario.selectedScenario.id !== null && props.scenario.selectedScenario.id !== undefined) {
          onChangeScenario(props.scenario.selectedScenario.id);
        }
        if (scen?.length > 0) {
          scen[0]?.steps.map((step) => {
            if (step.type === "scale")
              setScale(step);
          })
        }
        setFirst(false)
      }
    }catch(error){
        console.log("Error loading scenario", error);
    }

    return () => {
      isMounted = false;
    }
  }, []);


  useEffect(() => {
    try{
      getScenario();
    }catch(error){
      console.log("Error loading scenario", error);
    }
  }, [getScenario]);


  return (
    <div className="raw-container">
      <DashboardHeader>
        <HeaderBar
          title="Visualisation des réponses"
          header={headerPayload}
          nbrTester={headerPayload?.testers}
          scenarios={scenarios}
          onChangeScenario={onChangeScenario}
          selectedScenario={props.scenario.selectedScenario} 
          renderType="allData"
          skeleton={loading}
          excel={true}
        />
      </DashboardHeader>
      {
        choice === "scenario" ? (
          <>
            {
              loading ? (
                <div className="allDataResponseLoadersContainer">
                  <SpinnerLoader />
                </div>
              ):(
                <div className="mb-5">
                  <FirstResume _id={_id} scale={scale} />
                </div>
              )
            }
          </>
        ):(
          <NoDataSelected title="Veuillez sélectionner un scénario." />
        )
      }
    </div>
  );
}

const mapStateToProps = state => ({
  scenario: state.scenario,
  selectedScenario: state.selectedScenario
});

export default connect(
  mapStateToProps,
  {}
)(RawData);