import React, { useState, useEffect } from "react";
import { Collapse } from 'react-collapse';
import commArrow from '../../../../assets/commArrow.svg'
function ClosedComponentByTester(props) {

  const [arrayChoices, setArrayChoices] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [show, setShow] = useState(false);


  useEffect(() => {

    // const localArray = Object.keys(props?.ids?.questionChoices).filter(Boolean);
    const localArray = Object.values(
      Object.keys(props?.ids?.questionChoices)
        ?.filter((key) => key.startsWith("choice"))
        .reduce((acc, key) => {
          return {
            ...acc,
            [key]: props?.ids?.questionChoices[key],
          };
        }, {})
    ).filter(Boolean);
    setArrayChoices(localArray);
    setSelectedAnswer(props?.answer);
  }, []);
  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 20, textAlign: 'start' }}>
          < span className="question-text">
            {props?.ids?.question}
          </span>
        </div>
      </div>
      <div style={{ flexDirection: "column" }}>
        <div style={{ paddingLeft: 10, marginTop: 20 }}>
          <strong>
            {arrayChoices.map((resp) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 10,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={
                      resp === selectedAnswer
                        ? {
                          height: 24,
                          width: 24,
                          borderRadius: 12,
                          backgroundColor: "#17A25C",
                        }
                        : {
                          height: 24,
                          width: 24,
                          borderRadius: 12,
                          backgroundColor: "#e5e5e5",
                        }
                    }
                  />
                  <div style={{ padding: "5px 28px ", textAlign: 'start' }}>{resp}</div>
                </div>
              );
            })}
          </strong>
        </div>
      </div>
      {
        props?.comment ?

          <div style={{
            display: 'flex',
            justifyContent: 'start',
            background: 'rgba(196, 196, 196, 0.1)',
            padding: '5px 10px',
            borderRadius: '10px',
            cursor: 'pointer',
            marginTop: '1em'
          }} onClick={() => setShow(!show)}>
            <span style={{
              fontFamily: 'sans-serif',
              color: '#00A359',
              fontSize: '18px',
              paddingLeft: 20
            }}
              className="mr-3">Commentaires</span>
            {
              !show ?
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_0" />
                :
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_180" />
            }
          </div>
          : null
      }
      <Collapse isOpened={!show}>
        {props?.comment &&
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em', marginTop: '1em' }}>
            <div className="testeur-response">
              <span className="testeur-response-container-Commentaires">
                {props?.comment}.
              </span>
            </div>
          </div>}
      </Collapse>
    </div>
  );
}

export default ClosedComponentByTester;
