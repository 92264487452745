import React from 'react'
import { Spinner } from 'reactstrap'
import Modals from '../../../common/modal';

const RenderLoadingModal = ({ open, onToggleClose }) => {
    return (
        <Modals
            show={open}
            toggleopen={onToggleClose}
            header='Notification'
        >
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <span style={{ textAlign: 'center' }}>
                    En cours de traitement...
                </span>
                <br />
                <div>
                    <Spinner type="grow" color="success" className='' style={{ width: '1.5rem', height: '1.5rem' }} />
                    <Spinner type="grow" color="success" className='' style={{ width: '1.5rem', height: '1.5rem' }} />
                    <Spinner type="grow" color="success" className='' style={{ width: '1.5rem', height: '1.5rem' }} />
                </div>
                <br />
            </div>
        </Modals>
    )
}

export default RenderLoadingModal;