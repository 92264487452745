/**
 * React imports
 */
import React from "react";
import ScenariosDropList from "./ScenariosDropList";
import ScenarioDetails from "./ScenarioDetails";

/**
 * Renders the HeaderBar component.
 *
 * @param {Object} skeleton - The skeleton object.
 * @param {Object} header - The header object.
 * @param {number} nbrTester - The number of testers.
 * @param {Array} scenarios - The scenarios array.
 * @param {string} title - The title string.
 * @param {function} onChangeScenario - The callback function for scenario change.
 * @return {ReactNode} The rendered HeaderBar component.
 */
const HeaderBar = ({ title, header, nbrTester, scenarios, onChangeScenario, analyseObj, skeleton, renderType, excel=false })=> {
    
    /** RENDER */
    return(
        <>
            <div className='analyse-title-section'>
                <span className="analyse-title">{title}</span>
            </div>
            <div className='header-analyse'>
                <div className="sub-header-title-section">
                    <span className="sub-header-title">Sélectionner un scénario:</span>
                </div>
                <ScenariosDropList scenarios={scenarios} onChangeScenario={onChangeScenario}  renderType={renderType}/>
                <ScenarioDetails skeleton={skeleton} header={header} nbrTester={nbrTester} excel={excel} analyseObj={analyseObj} renderType={renderType}/>
            </div>
        </>
    )
}

export default HeaderBar;