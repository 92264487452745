import React from 'react'

/**
 * Displays a message when no data is selected
 * @param {{title: string}} props
 * @prop {string} title - The title to display
 * @returns {JSX.Element}
 */
const NoDataSelected = ({title}) => {
    return (
        <div className="allDataResponseLoadersContainer">
            <span className="allDataResponseLoaders">
                {title}
            </span>
        </div>
    )
}

export default NoDataSelected