import React from 'react';
import Table from "../../common/table"
import { connect } from 'react-redux';
import { onGetScenarios, onGetPanels } from "../../../actions/scenarioActions"
import ShowMore from "../../../assets/insightdata_consulter.svg"
import { Col } from "reactstrap"
import "../scenario.css"
import RightSideAdmin from '../../dashboard/admin/RightSideAdmin';
class ListPanel extends React.Component {
    state = {
        data: [],
        details: {},
        show: false
    }

    componentDidMount() {
        if (!this.props.scenario.panels[0])
            this.props.onGetPanels()
        else {
            let panels = []
            this.props.scenario.panels.map(panel => {
                panels = [...panels, {
                    name: panel.name,
                    nbrtesters: panel.testersNb,
                    type: panel.type,
                    scenarioName: panel.scenarioName,
                }]
            })
            this.setState({ data: panels })
        }
    } 

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.scenario.panels !== nextProps.scenario.panels) {
            let panels = []
            nextProps.scenario.panels.map(panel => {
                panels = [...panels, {
                    name: panel.name,
                    nbrtesters: panel.testersNb,
                    type: panel.type,
                    scenarioName: panel.scenarioName,
                }]
            })
            this.setState({ data: panels })
        }
    }
    
    showDetails = (data) => {
        this.setState({ show: !this.state.show, details: !this.state.show && data })
    }
 
    renderTable = () => {
        const columns = [
            { title: "Nom", field: 'name' },
            { title: "Nombre de testeurs", field: 'nbrtesters' },
            { title: "Type", field: 'type' },
            { title: "Scénario associé", field: 'scenarioName' },
        ]
        const actions = [{
            icon: () => <img style={{ width: "30px" }} src={ShowMore} alt="" />,
            tooltip: 'Détails',
            onClick: (event, rowData) => this.showDetails(rowData),
        },

        ]

        return (
            <Table
                title='Liste des panels'
                columns={columns}
                data={this.state.data}
                actions={actions}
            />
        )
    }
    render() {
        return (

        <>
        <div className='display__flex__row-compte_client'>
          <Col md='10'>
            {this.renderTable()}
          </Col>
            <RightSideAdmin/>
        </div>
      </>    
    )}
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    scenario: state.scenario,
});

export default connect(
    mapStateToProps,
    { onGetScenarios,
        onGetPanels
    }
)(ListPanel);
