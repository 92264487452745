import React, { useEffect, useState } from "react";
import ClosedComponentByTester from "./ClosedComponentByTester";
import OpenComponentByTester from "./OpenComponentByTester";
import ScaleComponentByTester from "./ScaleComponentByTester";
import axios from "axios";
import { getAuthorization } from "../../../../services/userServices";
import { apiUrl } from "../../../../config.json";
const apiEndpoint = apiUrl;

function TesterResume(props) {
  const [loading, setLoading] = useState(true)
  const [_, setListOfTesters] = useState([]);




  const LoadTestersByScenario = (x) => {
    axios
      .get(apiEndpoint + `/api/scenario/${x}/testers`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        if (response?.data) {
          let testerListTri = [];
          //  trier la liste des testeurs par ordre alphabétique
          testerListTri = response?.data?.sort((a, b) => {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
              return -1;
            }
            return 0;
          });
          setListOfTesters(testerListTri);
          setLoading(false)
        }
      })
      .catch((error) => {
        console.log("err", error);
        setLoading(false)
      });
  };





  useEffect(() => {
    LoadTestersByScenario(props?.senario);
    // LoadScenarioByTester()
  }, [props]);




  return (
    <React.Fragment>
      {loading && <span style={{ fontWeight: 'bold' }}>Chargement...</span>}
      <div>
        {
          props?.ScenarioOfTester ?
            <>
              {
                props?.ScenarioOfTester?.map((ids) => {
                  if (ids.step.type === "close") {
                    return (
                      <ClosedComponentByTester ids={ids?.step} answer={ids?.answer} comment={ids?.comment} />
                    );
                  }
                  if (ids.step.type === "open") {
                    return (
                      <OpenComponentByTester ids={ids?.step} answer={ids?.answer} comment={ids?.comment} />
                    );
                  }
                  if (ids.step.type === "scale") {
                    return (
                      <ScaleComponentByTester ids={ids?.step} answer={ids?.answer} comment={ids?.comment} />
                    );
                  }
                })
              }
            </>
            : "Veuillez  selectionner un scenario"
        }
      </div>
    </React.Fragment>
  );
}

export default TesterResume;
