import React, { useMemo, useState } from 'react'
import LoaderButton from '../../../common/loaders/LoaderButton'
import { generateAuditPDF } from '../helpers/generatePDF';
import AuditReport from './components/AuditReport';


/**
 * A React component that displays a detailed UX audit report based on the provided data?.
 * It includes client information, main entity notes, strong points, areas for improvement, 
 * brief recommendations, and competitor analysis. A PDF of the report can be downloaded by 
 * clicking a button, with a loading spinner shown during the PDF generation process.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.data? - The data? used to populate the report.
 * 
 * @returns {JSX.Element} The rendered component.
*/
const AuditUXFlashReport = ({ formData }) => {

    /* HOOKS */
    const [loadingPDF, setLoadingPDF] = useState(false);

    /**
     * Initiates the download of a PDF report for the client.
     * Sets the loading state to true during the report generation process.
     * Uses the `generateAuditPDF` helper function with the client's name.
     * Catches and logs any errors that occur during PDF generation.
     * Resets the loading state to false once the process is complete.
     * Only executes if `data?` is available.
    */
    const handleDownloadRapport = async () => {        
        if(formData) {
            try {
                setLoadingPDF(true);
                await generateAuditPDF(formData?.clientName,3);
            } catch (error) {
                console.error(error);
            } finally {
                setLoadingPDF(false);
            }
        }
    }

    /* DATA */
    const dataTable= useMemo(() =>{
        const reportData = [];
        for (let i = 0; i < formData?.data?.length; i += 2) {
            reportData.push(formData?.data?.slice(i, i + 2));
        }

        return reportData;
    },[formData]);

    /*RENDER */
    return (
        <div className="pdf-container">
            {dataTable && dataTable.map((data, index) => (
                <AuditReport key={index} data={data} nbPage={index} clientName={formData?.clientName}/>
            ))}
            <LoaderButton className="btn-success my-5" loading={loadingPDF} name="Télècharger PDF" onClick={handleDownloadRapport} />
        </div>
    )
}

export default AuditUXFlashReport;