import React from "react";
import { Label } from "reactstrap";
import MaterialTable from "material-table";
const Table = (props) => {
    const { data, title, columns, actions } = props
    return (
        <MaterialTable
            style={{
                backgroundColor: "white", 
                border: "none ", 
                boxShadow: "none",
                borderCollapse: "separate", 
                borderSpacing: "0 1rem",
                width: "100%",
            }}
            title={<Label 
            style={{ 
                fontSize: "1.8rem", 
                color: "#00A359", 
                fontWeight: "400", 
                fontFamily:'roboto', 
                lineHeight: "25px",
                marginBottom: "2rem",
                }}>{title}</Label>}
            columns={
                columns
            }
            data={
                data
            }
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'lignes',
                    labelRowsPerPage: 'lignes par page:',
                    firstAriaLabel: 'Première page',
                    firstTooltip: 'Première page',
                    previousAriaLabel: 'Page précédente',
                    previousTooltip: 'Page précédente',
                    nextAriaLabel: 'Page suivante',
                    nextTooltip: 'Page suivante',
                    lastAriaLabel: 'Dernière page',
                    lastTooltip: 'Dernière page'
                },
            }}
        
            options={{
                pageSize:5,
                sorting: true,
                pageSizeOptions:[5,10],
                actionsColumnIndex: -1,
                cellStyle: {
                    lineHeight: "1",
                    textAlign: "start",
                    fontSize: ".9rem"
                },
                headerStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                    backgroundColor:"#F3FBF7",
                    fontSize: ".9rem",
                    fontWeight: "600",
                    fontFamily:'roboto',
                },
            searchFieldStyle: {
            color: "#00a359",
            MuiInputUnderline: "none",
            marginBottom: "1.8rem",
            },
      }}
    />
  );
};
export default Table;
