import React from "react";
import { connect } from "react-redux";
import { onGetClients ,onGetTesters} from "../../../actions/userActions";
import { onGetScenarios } from "../../../actions/scenarioActions"
import { Col } from 'reactstrap'

class RightSideAdmin extends React.Component {
  state = {
    countSubClients: 1,
    countTesteurs: 0,
    countScenario: 0,
  };

  componentDidMount() {
    if(this.props.scenario?.scenarios){
      if (!this.props.scenario?.scenarios?.length > 0)
      this.props.onGetScenarios()
      else {
          let scenariosOuvert = 0
          this.props.scenario.scenarios.map(scenario => {
              if(scenario.etat === 3){
                  scenariosOuvert = scenariosOuvert + 1
              }
          })
          this.setState({ countScenario: scenariosOuvert })
      }
      if (!this.props.user.testers[0])
        this.props.onGetTesters()
      else {
        this.setState({ countTesteurs: this.props?.user?.testers?.length })
      }
    
      if (!this.props.user.users[0]) 
        this.props.onGetClients();
      else {
        this.setState({ countSubClients: this.props?.user?.users?.length });
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.user.users !== nextProps.user.users) {
      this.setState({ countSubClients: nextProps?.user?.users?.length });
      this.setState({ countTesteurs: nextProps?.user?.testers?.length });
    }
    if (this.props.scenario.scenarios !== nextProps.scenario.scenarios) {
      // let scenariosOuvert = 0
      // console.log(nextProps.scenario.scenarios);
      // this.props.scenario.scenarios.map(scenario => {
      //     if(scenario.etat === 3){
      //         scenariosOuvert = scenariosOuvert + 1
      //     }
      // })
      // this.setState({ countScenario: scenariosOuvert })
    }
  }

  render() {
    return (
      <>
        <Col md='2' className='dashboard-right_side'>
          <div className='scenario__info-compte'>
            <Col className="col__style-compte_client">
                <span className='text-square mb-3'>Montant A Facturer</span>
                <span className='square2'>0</span>
            </Col>
            <Col className="col__style-compte_client">
                <span className='text-square mb-3'>Scénario Ouverts</span>
                {
                    this.state?.countSubClients ? 
                    <span className='square4'>{this.state?.countScenario}</span>
                    :
                    <span className='square2'>0</span>
                }
            </Col>
            <Col className="col__style-compte_client">
                <span className='text-square mb-3'>Clients</span>
                {
                    this.state?.countSubClients ? 
                    <span className='square4'>{this.state?.countSubClients}</span>
                    :
                    <span className='square2'>1</span>
                }
            </Col>
            <Col className="col__style-compte_client">
                <span className='text-square mb-3'>Testeurs</span>
                {
                    this.state?.countSubClients ? 
                    <span className='square4'>{this.state?.countTesteurs}</span>
                    :
                    <span className='square2'>1</span>
                }
            </Col>
          </div>
        </Col>
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  scenario: state.scenario,
});

export default connect(mapStateToProps, { onGetClients, onGetScenarios, onGetTesters })(RightSideAdmin);
