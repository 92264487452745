import React, { Component } from 'react';
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { menuLandingAdmin } from '../../../utils/routes'
import Welcome from '../../common/welcome';
import "../landingPage.css"

class LandingAdmin extends Component {
    state = {
        welcome: true
    }
    componentDidMount() {
        setTimeout(() => {
            this.setState({ welcome: false })
        }, 2000);
    }
    render() {
        const { lng } = this.props.auth


        return (
            <>
                {this.state.welcome && <Welcome
                    data={this.props.auth.user.name}
                />}
                {!this.state.welcome && 
                    <div style={{ minHeight: '550px' }}>
                        <Switch>
                            {
                                menuLandingAdmin(lng).map(route => <Route key={route.id} exact path={`/admin${route.path}`} component={route.component} />)
                            }
                        </Switch>
               
                </div>}
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth
});

export default connect(mapStateToProps)(LandingAdmin);
