  export const genreList = [
    { id:1, label: "Homme", value: "Homme" },
    { id:2, label: "Femme", value: "Femme" },
  ];
  export const genreEnfantList = [
    { id:1,  name: "Homme" },
    { id:2,  name: "Femme" },
  ];

  export const achatInternet = [
    { id:1, label: "Oui", value: "Oui" },
    { id:2, label: "Non", value: "Non" },
  ];

  export const LangueList = [
    { id:1, label: "Arabe",  value: "Arabe" },
    { id:2, label: "Français",  value: "Français" },
    { id:3, label: "Anglais",  value: "Anglais" },
    { id:4, label: "Espagnol",  value: "Espagnol" },
    { id:5, label: "Allemand",  value: "Allemand" },
    { id:6, label: "Italien",  value: "Italien" },
    { id:7, label: "Chinois",  value: "Chinois" },
    { id:8, label: "Japonais",  value: "Japonais" },
    { id:9, label: "Russe",  value: "Russe" },
    { id:10, label: "Portugais",  value: "Portugais" },
    { id:11, label: "Turc",  value: "Turc" },
    { id:12, label: "Hindi",  value: "Hindi" },
    { id:13, label: "Coréen",  value: "Coréen" },
    { id:14, label: "Néerlandais",  value: "Néerlandais" },
    { id:15, label: "Suédois",  value: "Suédois" },
    { id:16, label: "Norvégien",  value: "Norvégien" },
    { id:17, label: "Danois",  value: "Danois" },
    { id:18, label: "Finnois",  value: "Finnois" },
    { id:19, label: "Polonais",  value: "Polonais" },
    { id:20, label: "Grec",  value: "Grec" },
    { id:21, label: "Hongrois",  value: "Hongrois" },
    { id:22, label: "Roumain",  value: "Roumain" },
    { id:23, label: "Tchèque",  value: "Tchèque" },
    { id:24, label: "Slovaque",  value: "Slovaque" },
    { id:25, label: "Slovène",  value: "Slovène" },
    { id:26, label: "Bulgare",  value: "Bulgare" },
    { id:27, label: "Croate",  value: "Croate" },
    { id:28, label: "Serbe",  value: "Serbe" },
    { id:29, label: "Macédonien",  value: "Macédonien" },
    { id:30, label: "Bosniaque",  value: "Bosniaque" },
    { id:31, label: "Albanais",  value: "Albanais" },
  ];

  export const systemeList = [
    { id:1, label: "Windows XP ou inférieur", name: "Windows XP ou inférieur" },
    { id:2, label: "Windows Vista", name: "Windows Vista" },
    { id:3, label: "Windows Phone", name: "Windows Phone" },
    { id:4, label: "Windows 8", name: "Windows 8" },
    { id:5, label: "Windows 7", name: "Windows 7" },
    { id:6, label: "Windows 10 ou supérieur", name: "Windows 10 ou supérieur" },
    { id:7, label: "Symbian", name: "Symbian" },
    { id:8, label: "OS X 10.10", name: "OS X 10.10" },
    { id:9, label: "OS X 10.11", name: "OS X 10.11" },
    { id:10, label: "OS X 10.9", name: "OS X 10.9" },
    { id:11, label: "OS X 10.8", name: "OS X 10.8" },
    { id:12, label: "OS X 10.7", name: "OS X 10.7" },
    { id:13, label: "OS X 10.6", name: "OS X 10.6" },
    { id:14, label: "OS X 10.5", name: "OS X 10.5" },
    { id:15, label: "Non Connu", name: "Non Connu" },
    { id:16, label: "Kindle", name: "Kindle" },
    { id:17, label: "iOS 11 ou supérieur", name: "iOS 11 ou supérieur" },
    { id:18, label: "iOS 10", name: "iOS 10" },
    { id:19, name: "iOS 9" },
    { id:20, name: "iOS 8" },
    { id:22, name: "iOS 7 ou inférieur" },
    { id:23, name: "BlackBerry" },
    { id:24, name: "Android 6 ou supérieur" },
    { id:25, name: "Android 5" },
    { id:26, name: "Android 4.4" },
    { id:27, name: "Android 4.3" }
  ];

  export const situtaionMaritalList = [
    { id:1, label: "Célibataire", value: "Célibataire" },
    { id:2, label: "Marié(e)", value: "Marié(e)" },
    { id:3, label: "Divorcé(e)", value: "Divorcé(e)" },
    { id:4, label: "Veuf(ve)", value: "Veuf(ve)" },
    { id:5, label: "Pacsé(e)", value: "Pacsé(e)" },
    { id:6, label: "Séparé(e)", value: "Séparé(e)" },
  ];

  export const niveauEtudeList = [
    { id:1, label: "Aucun diplôme", value: "Aucun diplôme" },
    { id:2, label: "Brevet des collèges, CAP, BEP ou autre", value: "Brevet des collèges, CAP, BEP ou autre" },
    { id:3, label: "Bac, Brevet professionnel", value: "Bac, Brevet professionnel" },
    { id:4, label: "Bac +2", value: "Bac +2" },
    { id:5, label: "Bac +3 ou 4", value: "Bac +3 ou 4" },
    { id:6, label: "Bac +5", value: "Bac +5" },
  ];

  export const departementList = [
    { id:1, label: "Conception", value: "Conception" },
    { id:2, label: "Communication", value: "Communication" },
    { id:3, label: "Production et Fabrication", value: "Production et Fabrication" },
    { id:4, label: "Management", value: "Management" },
    { id:5, label: "Distribution", value: "Distribution" },
    { id:6, label: "Finance", value: "Finance" },
    { id:7, label: "Achat et Approvisionnement", value: "Achat et Approvisionnement" },
    { id:8, label: "Logistique et Transport", value: "Logistique et Transport" },
    { id:9, label: "Contrôle et qualité", value: "Contrôle et qualité" },
    { id:10, label: "Vente", value: "Vente" },
    { id:11, label: "Ressources Humaines", value: "Ressources Humaines" },
    { id:12, label: "Informatique", value: "Informatique" },
    { id:13, label: "Juridique", value: "Juridique" },
    { id:14, label: "Marketing", value: "Marketing" },
    { id:15, label: "Infrastructure et Sécurité", value: "Infrastructure et Sécurité" },
    { id:16, label: "Comptabilité", value: "Comptabilité" },
    { id:17, label: "Direction et Stratégie", value: "Direction et Stratégie" },
    { id:18, label: "Service à la clientèle", value: "Service à la clientèle" },
    { id:19, label: "Recherche et développement", value: "Recherche et développement" },
    { id:20, label: "Non concerné", value: "Non concerné" },
  ];

  export const revenuList = [
    { id:1, label: "Moins de 800€", value: "Moins de 800€" },
    { id:2, label: "Entre 800€ et 1500€", value: "Entre 800€ et 1500€" },
    { id:3, label: "Entre 1501€ et 2000€", value: "Entre 1501€ et 2000€" },
    { id:4, label: "Entre 2001€ et 3500€", value: "Entre 2001€ et 3500€" },
    { id:5, label: "Entre 3501€ et 5000€", value: "Entre 3501€ et 5000€" },
    { id:6, label: "Plus de 5000€", value: "Plus de 5000€" },
  ];

  export const fonctionManagérialeList = [
    { id:1, label: "Indépendant", value: "Indépendant" },
    { id:2, label: "Non concerné", value: "Non concerné" },
    { id:3, label: "Pas de fonction managériale", value: "Pas de fonction managériale" },
    { id:4, label: "Management d’équipe", value: "Management d’équipe" },
    { id:5, label: "Responsable fonctionnel", value: "Responsable fonctionnel" },
    { id:6, label: "Direction de pôle", value: "Direction de pôle" },
    { id:7, label: "Direction générale", value: "Direction générale" },
    { id:8, label: "Assistant de direction", value: "Assistant de direction " },
  ];


  export const secteurTravaille = [
    { id:1, label: "Agroalimentaire", value: "Agroalimentaire" },
    { id:2, label: "Banque / Assurance", value: "Banque / Assurance" },
    { id:3, label: "Bois / Papier / Carton / Imprimerie ", value: "Bois / Papier / Carton / Imprimerie " },
    { id:4, label: "BTP / Matériaux de construction ", value: "BTP / Matériaux de construction " },
    { id:5, label: "Chimie / Parachimie ", value: "Chimie / Parachimie " },
    { id:6, label: "Commerce / Négoce / Distribution", value: "Commerce / Négoce / Distribution" },
    { id:7, label: "Édition / Communication / Multimédia ", value: "Édition / Communication / Multimédia " },
    { id:8, label: "Édition / Communication / Multimédia ", value: "Édition / Communication / Multimédia " },
    { id:9, label: "Études et conseils", value: "Études et conseils" },
    { id:10, label: "Industrie pharmaceutique", value: "Industrie pharmaceutique" },
    { id:11, label: "Informatique / Télécoms", value: "Informatique / Télécoms" },
    { id:12, label: "Machines et équipements / Automobile", value: "Machines et équipements / Automobile" },
    { id:13, label: "Métallurgie / Travail du métal ", value: "Métallurgie / Travail du métal " },
    { id:14, label: "Plastique / Caoutchouc", value: "Plastique / Caoutchouc" },
    { id:15, label: "Services aux entreprises", value: "Services aux entreprises" },
    { id:16, label: "Textile / Habillement / Chaussure ", value: "Textile / Habillement / Chaussure " },
    { id:17, label: " Transports / Logistique ", value: " Transports / Logistique " },
  ];


  export const taileSocieteList = [
    { id:1, label: "Non concerné", value: "Non concerné" },
    { id:2, label: "Indépendant", value: "Indépendant " },
    { id:3, label: "Moins de 10 salariés", value: "Moins de 10 salariés" },
    { id:4, label: "Entre 10 et 49 salariés", value: "Entre 10 et 49 salariés" },
    { id:5, label: "Entre 50 et 99 salariés", value: "Entre 50 et 99 salariés" },
    { id:6, label: "Entre 100 et 249 salariés", value: "Entre 100 et 249 salariés" },
    { id:7, label: "Entre 250 et 499 salariés", value: "Entre 250 et 499 salariés" },
    { id:8, label: "Entre 500 et 999 salariés", value: "Entre 500 et 999 salariés" },
    { id:9, label: "ntre 1000 et 4999 salariés", value: "Entre 1000 et 4999 salariés" },
    { id:10, label: "Plus de 5000 salariés", value: "Plus de 5000 salariés" },
  ];

  export const tempsInternetList = [
    { id:1, label: "Moins de 5 minutes", value: "Moins de 5 minutes" },
    { id:2, label: "5 à 30 minutes", value: "5 à 30 minutes" },
    { id:3, label: "30 à 60 minutes", value: "30 à 60 minutes" },
    { id:4, label: "1-3 heures", value: "1-3 heures" },
    { id:5, label: "3 heures et plus", value: "3 heures et plus" },
  ];

  export const formInfoInternet = [
    { id:1, label: "Au moins 1 fois par mois", value: "Au moins 1 fois par mois" },
    { id:2, label: "Au moins 1 fois tous les 3 mois", value: "Au moins 1 fois tous les 3 mois" },
    { id:3, label: "Au moins 1 fois tous les 6 mois", value: "Au moins 1 fois tous les 6 mois" },
    { id:4, label: "Au moins 1 fois par an", value: "Au moins 1 fois par an" },
    { id:5, label: "Jamais", value: "Jamais" },
  ];


  export const cspList = [
    { id:1, label: "Agriculteurs exploitants", value:"Agriculteurs exploitants"},
    { id:2, label: "Artisans", value:"Artisans"}, 
    { id:3, label: "Commerçants et chefs d’entreprise", value:"Commerçants et chefs d’entreprise"}, 
    { id:4, label: "Cadres et professions intellectuelles supérieures", value:"Cadres et professions intellectuelles supérieures"}, 
    { id:5, label: "Employés", value:"Employés"}, 
    { id:6, label: "Ouvriers", value:"Ouvriers"},
    { id:7, label: "Professions Intermédiaires", value:"Professions Intermédiaires"}
  ];


  export const reseauSociauxList = [
    { label: "Je n’utilise pas les réseaux sociaux", value: "Je n’utilise pas les réseaux sociaux" },
    { label: 'Blogs', value: "Blogs" },
    { label: "Facebook", value: "Facebook" },
    { label: "Flickr", value: "Flickr" },
    { label: "Instagram", value: "Instagram" },
    { label: "LinkedIn", value: "LinkedIn" },
    { label: 'Pinterest', value: "Pinterest" },
    { label: "Periscope", value: "Periscope" },
    { label: 'Snapchat', value: "Snapchat" },
    { label: 'Twitter', value: "Twitter" },
    { label: 'TikTok', value: "TikTok" },
    { label: 'Tumblr', value: "Tumblr" },
    { label: 'Twitch', value: "Twitch" },
    { label: 'Viadeo', value: "Viadeo" },
    { label: 'YouTube', value: "YouTube " },
  ]


  export const centerInteretList = [
    { label: "Art et culture", value: "Art et culture" },
    { label: "Communication & Média", value: "Communication & Média" },
    { label: "Économie", value: "Économie " },
    { label: "Écologie et développement durable", value: "Écologie et développement durable" },
    { label: "Mode", value: "Mode " },
    { label: "Gastronomie", value: "Gastronomie" },
    { label: "Nouvelles technologies", value: "Nouvelles technologies" },
    { label: "Politique", value: "Politique " },
    { label: "Santé & Bien-être", value: "Santé & Bien-être" },
    { label: "Spectacles", value: "Spectacles " },
    { label: "Sport", value: "Sport " }, 
    { label: "Tourisme & Voyages", value: "Tourisme & Voyages" },
    { label: "Musique", value: "Musique " },
    { label: "Sciences", value: "Sciences" },
  ]

  export const activitestList = [
    { label: "Activité manuelle", value: "Activité manuelle" },
    { label: "Cinéma", value: "Cinéma " },
    { label: "Concert, Théâtre ", value: "Concert, Théâtre " },
    { label: "Cuisine", value: "Cuisine " },
    { label: "Jardinage, Bricolag", value: "Jardinage, Bricolag " },
    { label: "Lecture", value: "Lecture " },
    { label: "Musique", value: "Musique " },
    { label: "Photo ou vidéo", value: "Photo ou vidéo " },
    { label: "Sport", value: "Sport " },
    { label: "Activités nautiques", value: "Activités nautiques " },
  ];


  export const paysList = [
    "Afghanistan",
    "Afrique du Sud",
    "Albanie",
    "Algérie",
    "Allemagne",
    "Andorre",
    "Angola",
    "Anguilla",
    "Antarctique",
    "Antigua-et-Barbuda",
    "Antilles néerlandaises",
    "Arabie saoudite",
    "Argentine",
    "Arménie",
    "Aruba",
    "Australie",
    "Autriche",
    "Azerbaïdjan",
    "Bahamas",
    "Bahreïn",
    "Bangladesh",
    "Barbade",
    "Bélarus",
    "Belgique",
    "Belize",
    "Bénin",
    "Bermudes",
    "Bhoutan",
    "Bolivie",
    "Bosnie-Herzégovine",
    "Botswana",
    "Brésil",
    "Brunéi Darussalam",
    "Bulgarie",
    "Burkina Faso",
    "Burundi",
    "Cambodge",
    "Cameroun",
    "Canada",
    "Cap-Vert",
    "Ceuta et Melilla",
    "Chili",
    "Chine",
    "Chypre",
    "Colombie",
    "Comores",
    "Congo-Brazzaville",
    "Corée du Nord",
    "Corée du Sud",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatie",
    "Cuba",
    "Danemark",
    "Diego Garcia",
    "Djibouti",
    "Dominique",
    "Égypte",
    "El Salvador",
    "Émirats arabes unis",
    "Équateur",
    "Érythrée",
    "Espagne",
    "Estonie",
    "État de la Cité du Vatican",
    "États fédérés de Micronésie",
    "États-Unis",
    "Éthiopie",
    "Fidji",
    "Finlande",
    "France",
    "Gabon",
    "Gambie",
    "Géorgie",
    "Géorgie du Sud et les îles Sandwich du Sud",
    "Ghana",
    "Gibraltar",
    "Grèce",
    "Grenade",
    "Groenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernesey",
    "Guinée",
    "Guinée équatoriale",
    "Guinée-Bissau",
    "Guyana",
    "Guyane française",
    "Haïti",
    "Honduras",
    "Hongrie",
    "Île Bouvet",
    "Île Christmas",
    "Île Clipperton",
    "Île de l'Ascension",
    "Île de Man",
    "Île Norfolk",
    "Îles Åland",
    "Îles Caïmans",
    "Îles Canaries",
    "Îles Cocos - Keeling",
    "Îles Cook",
    "Îles Féroé",
    "Îles Heard et MacDonald",
    "Îles Malouines",
    "Îles Mariannes du Nord",
    "Îles Marshall",
    "Îles Mineures Éloignées des États-Unis",
    "Îles Salomon",
    "Îles Turks et Caïques",
    "Îles Vierges britanniques",
    "Îles Vierges des États-Unis",
    "Inde",
    "Indonésie",
    "Irak",
    "Iran",
    "Irlande",
    "Islande",
    "Israël",
    "Italie",
    "Jamaïque",
    "Japon",
    "Jersey",
    "Jordanie",
    "Kazakhstan",
    "Kenya",
    "Kirghizistan",
    "Kiribati",
    "Koweït",
    "Laos",
    "Lesotho",
    "Lettonie",
    "Liban",
    "Libéria",
    "Libye",
    "Liechtenstein",
    "Lituanie",
    "Luxembourg",
    "Macédoine",
    "Madagascar",
    "Malaisie",
    "Malawi",
    "Maldives",
    "Mali",
    "Malte",
    "Maroc",
    "Martinique",
    "Maurice",
    "Mauritanie",
    "Mayotte",
    "Mexique",
    "Moldavie",
    "Monaco",
    "Mongolie",
    "Monténégro",
    "Montserrat",
    "Mozambique",
    "Myanmar",
    "Namibie",
    "Nauru",
    "Népal",
    "Nicaragua",
    "Niger",
    "Nigéria",
    "Niue",
    "Norvège",
    "Nouvelle-Calédonie",
    "Nouvelle-Zélande",
    "Oman",
    "Ouganda",
    "Ouzbékistan",
    "Pakistan",
    "Palaos",
    "Panama",
    "Papouasie-Nouvelle-Guinée",
    "Paraguay",
    "Pays-Bas",
    "Pérou",
    "Philippines",
    "Pitcairn",
    "Pologne",
    "Polynésie française",
    "Porto Rico",
    "Portugal",
    "Qatar",
    "R.A.S. chinoise de Hong Kong",
    "R.A.S. chinoise de Macao",
    "régions éloignées de l’Océanie",
    "République centrafricaine",
    "République démocratique du Congo",
    "République dominicaine",
    "République tchèque",
    "Réunion",
    "Roumanie",
    "Royaume-Uni",
    "Russie",
    "Rwanda",
    "Sahara occidental",
    "Saint-Barthélémy",
    "Saint-Kitts-et-Nevis",
    "Saint-Marin",
    "Saint-Martin",
    "Saint-Pierre-et-Miquelon",
    "Saint-Vincent-et-les Grenadines",
    "Sainte-Hélène",
    "Sainte-Lucie",
    "Samoa",
    "Samoa américaines",
    "Sao Tomé-et-Principe",
    "Sénégal",
    "Serbie",
    "Serbie-et-Monténégro",
    "Seychelles",
    "Sierra Leone",
    "Singapour",
    "Slovaquie",
    "Slovénie",
    "Somalie",
    "Soudan",
    "Sri Lanka",
    "Suède",
    "Suisse",
    "Suriname",
    "Svalbard et Île Jan Mayen",
    "Swaziland",
    "Syrie",
    "Tadjikistan",
    "Taïwan",
    "Tanzanie",
    "Tchad",
    "Terres australes françaises",
    "Territoire britannique de l'océan Indien",
    "Territoire palestinien",
    "Thaïlande",
    "Timor oriental",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinité-et-Tobago",
    "Tristan da Cunha",
    "Tunisie",
    "Turkménistan",
    "Turquie",
    "Tuvalu",
    "Ukraine",
    "Union européenne",
    "Uruguay",
    "Vanuatu",
    "Venezuela",
    "Viêt Nam",
    "Wallis-et-Futuna",
    "Yémen",
    "Zambie",
    "Zimbabwe"
  ];