import React from "react";
import { Label } from "reactstrap";
import MaterialTable from "material-table";
const ClickableTable = (props) => {
    const { data, title, columns, actions, onRowClick } = props
    return (
        <MaterialTable
            style={{
                backgroundColor: "white",
                border: "none ",
                boxShadow: "none",
                borderCollapse: "separate",
                borderSpacing: "0 1rem",
                width: "100%",
            }}
            title={<Label
                style={{
                    fontSize: "1.8rem",
                    color: "#00A359",
                    fontWeight: "400",
                    lineHeight: "25px",
                    marginBottom: "2rem", fontFamily: 'sans-serif'
                }}>{title}</Label>}
            columns={
                columns
            }
            data={
                data
            }
            actions={actions}
            localization={{
                body: {
                    emptyDataSourceMessage: 'Aucune donnée à afficher',
                },
                pagination: {
                    labelDisplayedRows: '{from}-{to} de {count}',
                    labelRowsSelect: 'lignes',
                    labelRowsPerPage: 'lignes par page:',
                    firstAriaLabel: 'Première page',
                    firstTooltip: 'Première page',
                    previousAriaLabel: 'Page précédente',
                    previousTooltip: 'Page précédente',
                    nextAriaLabel: 'Page suivante',
                    nextTooltip: 'Page suivante',
                    lastAriaLabel: 'Dernière page',
                    lastTooltip: 'Dernière page'
                },
            }}
            onRowClick={onRowClick}
            options={{
                pageSize: 5,
                pageSizeOptions: [5, 10, 15],
                actionsColumnIndex: -1,
                cellStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                },
                headerStyle: {
                    lineHeight: "1 ",
                    textAlign: "start",
                    backgroundColor: "#f3fbf7",
                },
                searchFieldStyle: {
                    color: "#00a359",
                    MuiInputUnderline: "none",
                },
            }}
        />
    );
};
export default ClickableTable;
