import React from "react";
import Table from "../../common/TableDashboard";
import { connect } from "react-redux";
import { onGetAdminScenarios } from "../../../actions/scenarioActions"
import ShowMore from "../../../assets/loupe.svg";
import { Col } from 'reactstrap'
import moment from "moment";
import RightSideAdmin from "./RightSideAdmin";


class DashboardAdmin extends React.Component {
  state = {
    data: [],
    details: {},
    anneeList: [],
    show: false,
    showContrat: false,
    idActClient: null,
    actionSection: false,
  };

  componentDidMount() {
    if(this.props.scenario?.scenarios){
      if (!this.props.scenario?.scenarios?.length > 0) {
        this.props.onGetAdminScenarios()
      }
      else {
        let scenarios = []
        this.props.scenario.scenarios.map(scenario => {
          scenarios = [...scenarios, {
            id: scenario.id,
            createdAt: scenario.createdAt,
            product: scenario.product,
            isModerate: scenario.isModerate,
            title: scenario.title,
            progress: scenario.progress,
            isUnique: scenario.isUnique,
            state: scenario.state,
            panel: scenario.panel,
            etat: scenario.etat,
            color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
          }]
        })
        this.setState({ data: scenarios })
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.scenario.scenarios !== nextProps.scenario.scenarios) {
      let scenarios = []
      nextProps.scenario.scenarios?.map(scenario => {
        scenarios = [...scenarios, {
          id: scenario.id,
          createdAt: scenario.createdAt,
          product: scenario.product,
          isModerate: scenario.isModerate,
          title: scenario.title,
          progress: scenario.progress,
          isUnique: scenario.isUnique,
          state: scenario.state,
          panel: scenario.panel,
          etat: scenario.etat,
          color: (moment(new Date()).format('L') > moment(scenario.createdAt).format('L') + "02") || (moment(new Date()).isAfter(scenario.createdAt, 'year')) ? "red" : ""
        }]
      })
      this.setState({ data: scenarios })
    }
  }


  renderTable = () => {
    const columns = [
      {
        title: "Date", field: 'createdAt',
        DateSort: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
        render: rowData => <span className={rowData.etat === 2 && 'bold'} >{rowData.createdAt}</span>
      },
      {
        title: "Nom de scénario", field: 'title',
        render: rowData => <span className={rowData.etat === 2 && 'bold'} >{rowData.title}</span>
      },
      {
        title: "Produit", field: 'product',
        render: rowData => <span className={rowData.etat === 2 && 'bold'} >{rowData.product}</span>
      },
      {
        title: "Typologie", field: 'isUnique',
        render: rowData => <span className={rowData.etat === 2 && 'bold'}>{(rowData.isUnique === true && rowData.isModerate === true) ? "Unique Modéré" : (rowData.isUnique === true && rowData.isModerate === false) ? "Unique Non Modéré" : (rowData.isUnique === false && rowData.isModerate === true) ? "A/B Testing Modéré" : "A/B Testing Non Modéré"}</span>
      },
      {
        title: "Client", field: '', render:
          rowData => <span className={rowData.etat === 2 && 'bold'} >{rowData.product}</span>
      },
      {
        title: "Etat", field: 'etat', render: rowData => <span className={rowData.etat === 2 && 'bold'} //style={{ color: `${rowData.color}` }}
        >{(rowData.etat === 0) ? "A compléter" : <span className={rowData.etat === 2 && 'bold'} >{rowData.etat === 1 ? "A associer" : <span className={rowData.etat === 2 && 'bold'} >{rowData.etat === 2 ? "A lancer" : <span className={rowData.etat === 2 && 'bold'} >{rowData.progress === 100 ? "Terminé" : "En cours"}</span>}</span>}</span>}</span>
      },
    ]
    const actions = [
      {
        icon: () => <img style={{ marginRight: '.25em', width: '20px', marginTop: '.125em' }} src={ShowMore} alt="" />,
        tooltip: 'Details',
      },

    ]

    return (
      <Table
        title='Liste des scénarios'
        columns={columns}
        data={this.state.data}
        actions={actions}
        pageSize={5}
      />
    )
  }

  render() {
    return (
      <>
        <div className='display__flex__row-compte_client'>
          <Col md='10'>
            <div>
              {/* <div className='favoris-dashboard mb-1'>
                <span className='db__title-admin'>Mes accès favoris</span>
                <div className='display__flex__row-dashboard'>
                    <Button className='shortcut_client' onClick={() => {
                        this.props.history.push("/admin/ListPanels")
                    }}>List des panels</Button>
                    <Button className='shortcut_client' onClick={() => {
                        this.props.history.push("/admin/clientValidation")
                    }}>Comptes clients</Button>
                    <Button className='shortcut_client' onClick={() => {
                        this.props.history.push("/admin/testerValidation")
                    }}>Comptes testeurs</Button>
                    <Button className='shortcut_client' onClick={() => {
                        this.props.history.push("/admin/licences")
                    }}>Gestion des licences</Button>
                </div>
            </div> */}
              <div className='tableContainer'>
                {this.renderTable()}
              </div>
            </div>
          </Col>
          <RightSideAdmin />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  scenario: state.scenario,
});

export default connect(mapStateToProps, { onGetAdminScenarios })(DashboardAdmin);
