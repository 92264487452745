import React, { useState, useEffect } from "react";
import FirstResume from "./rawComponents/FirstResume";
import StepResume from "./rawComponents/StepResume";
import axios from "axios";
import { getAuthorization } from "../../../services/userServices";
import { apiUrl } from "../../../config.json";
import TesterResume from "./rawComponents/TesterResume";
import vl from "../../../assets/arrowL.svg";
import vr from "../../../assets/arrowR.svg";
import '../../landingPages/client/rowData.css'
import {
  Input
} from "reactstrap";

const apiEndpoint = apiUrl;

function StatAllData() {

  const [choice, setChoice] = useState("scenario");
  const [_id, setId] = useState(0);
  const [scenarios, setScenarios] = useState([]);
  const [headerPayload, setHeaderPayload] = useState([]);
  const [stepCount, setStepCount] = useState(1);
  const [stepId, setStepId] = useState();
  const [step, setStep] = useState(1);
  const [testersByScenario, setTestersByScenario] = useState([]);
  const [testerID, setTesterID] = useState();
  const [ScenarioOfTester, setScenarioOfTester] = useState([]);
  const [scale, setScale] = useState({});

  const getScenarios = () => {
    axios
      .get(apiEndpoint + "/api/scenario", {
        headers: getAuthorization(),
      })
      .then((response) => {
        let scen = {}
        let scena = []
        response.data.map(scenario => {
          let sortedSteps = scenario.steps.sort((a, b) =>
            a.id - b.id
          )
          scen = { ...scenario, steps: sortedSteps }
          scena = [...scena, scen]
        })
        setScenarios(scena)
      })
      .catch((error) => {
        console.log(error, "err");
      });
  };

  const getScenario = (id) => {
    if (id) {
      axios
        .get(apiEndpoint + `/api/scenario/${id}/details`, {
          headers: getAuthorization(),
        })
        .then((response) => {
          setHeaderPayload(response?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getStep = (id) => {
    if (id) {
      axios
        .get(apiEndpoint + `/api/stats/step/${id}`, {
          headers: getAuthorization(),
        })
        .then((response) => {
          setStep(response?.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const LoadTestersByScenario = (x) => {
    if (x) {
      axios
        .get(apiEndpoint + `/api/scenario/${x}/testers`, {
          headers: getAuthorization(),
        })
        .then((response) => {
          setTestersByScenario(response?.data);
          setTesterID(response?.data[0]?.id);
          LoadScenarioByTester(response?.data[0]?.id, _id);
        })
        .catch((error) => {
          console.log("err", error);
        });
    }
  };

  const LoadScenarioByTester = async (id, _id) => {
    if (id && _id)
      axios
        .get(apiEndpoint + `/api/stats/tester/${id}/${_id}`, {
          headers: getAuthorization(),
        })
        .then((response) => {
          let scen = {}
          response.data.map(scenario => {
            let sortedAnswers = scenario.answers.sort((a, b) => {
              return a.id - b.id
            })
            scen = { ...scenario, answers: sortedAnswers }
            setScenarioOfTester(scen)
          })
        })
        .catch((error) => {
          console.log("err", error);
        });
  };

  const TestersIds = (testersByScenario) => {
    if (testersByScenario.length === 0) {
      return null;
    }
    if (testersByScenario.length > 0) {
      for (let i = 0; i < testersByScenario.length; i++) {
        if (testersByScenario.length === !0) {
          return (
            <div>
              {testersByScenario.map((tbs) => {
                return (
                  <div>
                    {tbs.name} {i}
                  </div>
                );
              })}
            </div>
          );
        }
      }
    }
    return null;
  };

  const onChangeScenario = (e) => {
    setId(e.target.value);
    const scen = scenarios.filter(scen =>
      scen.id === e.target.value
    )

    setChoice("scenario");
    setStepId(scen[0]?.steps[0]?.id)
    setScale(scen[0]?.steps)
    scen[0]?.steps.map((step) => {
      if (step.type === "scale") {
        setScale(step);
      }
    })
  }

  useEffect(() => {
    getScenarios();
    stepId && getStep(stepId);
    getScenario(_id);
    if (_id !== 0) {
      LoadTestersByScenario(_id);
    }
  }, [_id, stepId, choice]);

  const incrementStep = (value, step) => {
    const getStepsId = getStepsIds()
    if (step + value > getStepsId?.length || step + value <= 0) {
      return null;
    }
    setStepId(getStepsId[step + value - 1]);
    setStepCount((prev) => prev + value);
    setChoice("step");
  };

  const getStepsIds = () => {
    const steps = scenarios?.find((sc) => sc?.id === _id)?.steps;
    const stepsIds = steps?.map((s) => s?.id);
    return stepsIds;
  };
  const StepCount = ({ incrimentTester, stepCount, nbTesters }) => {
    return (
      <div style={{ display: 'flex' }}>
        <span className="etape-all-data">Cliquez pour afficher un autre testeur</span>
        <div className="cursor-pointer mt-1" style={{ marginLeft: '1em' }}
          onClick={() => incrimentTester(-1)}>
          {
            stepCount === 1 ?
              <img src={vl} style={{ height: 12, width: 12 }} alt="2M-advisory" />
              :
              <img src={vr} style={{ height: 12, width: 12, transform: "rotate(180deg)" }} alt="2M-advisory" />
          }
        </div>
        <span className="count-all-data ml-2">{`${stepCount}`}</span>
        <span className="count-all-data">{`/`}</span>
        <span className="count-all-data mr-2">{`${nbTesters}`}</span>
        <div className="cursor-pointer mt-1" onClick={() => incrimentTester(1)}>
          {
            stepCount === testersByScenario?.length ?
              <img src={vl} style={{ height: 12, width: 12, transform: "rotate(180deg)" }} alt="2M-advisory" />
              :
              <img src={vr} style={{ height: 12, width: 12 }} alt="2M-advisory" />
          }
        </div>
      </div>
    );
  };

  const getStepsCount = (step) => {
    // const steps = scenarios?.find((sc) => sc?.id === _id)?.steps;
    const nbStep = scenarios?.find((sc) => sc?.id === _id)?.steps?.length;
    return (
      <div style={{ display: 'flex' }}>
        <span className="etape-all-data">Cliquez pour afficher une autre étape</span>
        <div className="cursor-pointer mt-1" style={{ marginLeft: '1em' }}
          onClick={() => incrementStep(-1, step)}>
          {
            step === 1 ?
              <img src={vl} style={{ height: 12, width: 12 }} alt="2M-advisory" />
              :
              <img src={vr} style={{ height: 12, width: 12, transform: "rotate(180deg)" }} alt="2M-advisory" />
          }
        </div>
        <span className="count-all-data ml-2">{`${step}`}</span>
        <span className="count-all-data">{`/`}</span>
        <span className="count-all-data mr-2">{`${nbStep}`}</span>
        <div className="cursor-pointer mt-1" onClick={() => incrementStep(1, step)}>
          {
            step === headerPayload[0]?.steps ?
              <img src={vl} style={{ height: 12, width: 12, transform: "rotate(180deg)" }} alt="2M-advisory" />
              :
              <img src={vr} style={{ height: 12, width: 12 }} alt="2M-advisory" />
          }
        </div>
      </div>
    );
  };

  const incrimentTester = (value) => {
    let nextCount = stepCount + value;
    if (
      nextCount - 1 + value > testersByScenario?.length ||
      nextCount <= 0
    ) {
      return null;
    }
    setTesterID(testersByScenario[nextCount - 1]?.id);
    setChoice("tester");
    setStepCount(nextCount);
  };

  useEffect(() => {
    LoadScenarioByTester(testerID, _id);
  }, [_id, testerID]);

  return (
    <div className="raw-container">
      <div className="col-top-component-container">
        <div className="s-top-component-container">
          <div style={{ marginBottom: '2em' }}>
            <span className="top-component-green-style2">Visualisation des réponses</span>
          </div>
          <div className="s-top-component-container-header">
            <div className="sub-sub-top-component-container" style={{ marginRight: '1em' }}>
              <span className="top-component-black-style3">Sélectionner un scénario</span>
            </div>
            <div>
              <Input type="select" name="select" className='btn_filter_select_scenario' id="exampleSelect" onChange={onChangeScenario}>
                <option selected disabled>Sélectionner un scénario</option>
                {scenarios.map((scen, idx) => {
                  return <option key={idx} value={scen.id} >{scen.title}</option>
                })
                }
              </Input>
            </div>
            <div className="all_data_etapes">
              <div className="sub-sub-top-component-container" style={{ marginRight: '2em' }}>
                <span className="top-component-black-style">Etapes</span>
                <div style={{ paddingLeft: 10 }}></div>
                <span className="top-component-fine-black-style">
                  {headerPayload[0]?.steps}
                </span>
              </div>

              <div className="sub-sub-top-component-container" style={{ marginRight: '2em' }}>
                <span className="top-component-black-style">Testeurs</span>
                <div style={{ paddingLeft: 10 }}></div>
                <span className="top-component-fine-black-style">
                  {headerPayload[0]?.testers}

                </span>
              </div>

              <div className="sub-sub-top-component-container" style={{ marginRight: '2em' }}>
                <span className="top-component-black-style">Pannel</span>
                <div style={{ paddingLeft: 10 }}></div>
                <span className="top-component-fine-black-style">
                  {headerPayload[0]?.type}
                </span>
              </div>
            </div>

          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "2em",
            filter: "drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.1))",
            width: "100%"
          }}>
          <div className="alldata" onClick={() => {
            setChoice("scenario");
          }}>
            <span
              className={choice === "scenario" ? "top-component-green-style cursor-pointer" : "top-component-black-style cursor-pointer"}
            >
              Données brutes
            </span>
          </div>
          <div className="alldata" onClick={() => {
            setChoice("step");
            setStepCount(1)
            getStep(stepId)
          }}>
            <span
              className={choice === "step" ? "top-component-green-style cursor-pointer" : "top-component-black-style cursor-pointer"}
            >
              Par étape
            </span>
          </div>
          <div className="alldata" onClick={() => {
            setChoice("tester");
            LoadTestersByScenario(_id);
            setStepCount(1)
          }}>
            <span
              className={choice === "tester" ? "top-component-green-style cursor-pointer" : "top-component-black-style cursor-pointer"}
            >
              Par testeur
            </span>
          </div>
        </div>
        {
          _id ?
            <>
              {["step"].includes(choice) && (
                <div className="alldata3">
                  <div style={{ textAlign: 'start' }}>
                    <span style={{ fontWeight: '600' }} >
                      Etape {step?.number}
                    </span>
                  </div>
                  {getStepsCount(step?.number)}
                </div>
              )}

              {choice === "tester" && (
                <div
                  className="alldata3"
                >
                  <span style={{ fontWeight: 600 }}>{`Testeur ${stepCount}`}</span>
                  <StepCount
                    stepCount={stepCount}
                    nbTesters={testersByScenario?.length}
                    incrimentTester={incrimentTester}
                  />
                </div>
              )}
            </>
            : null
        }
      </div>
      {choice === "scenario" ? (
        <>
          <FirstResume _id={_id} scale={scale} />
        </>
      ) : choice === "step" ? (
        <>
          <StepResume step={step} id={_id} count={stepCount} scale={scale} etape={step?.number} />
        </>
      ) : (
        choice === "tester" && (
          <>
            <TesterResume ScenarioOfTester={ScenarioOfTester.answers} senario={_id} />{" "}
            <TestersIds />
          </>
        )
      )}
    </div>
  );
}

export default StatAllData;
