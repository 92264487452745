import React, { useEffect, useState } from "react";
import { Button } from "reactstrap"
import { useHistory } from "react-router";
import { useSelector, useDispatch } from 'react-redux'
import { TAB_DASHBOARD_ITEM_CHANGED,TAB_DASHBOARD_MENU_CHANGED } from "../../typeActions/auth";
const Favorite = () => {

    const [isActive, setIsActive] = useState(null);
    const { selectedScenario } = useSelector((state) => state.scenario);
    const history = useHistory();
    const dispatch = useDispatch()
    
    useEffect(() => {
        switch (history.location.pathname) {
            case "/client/createscenario":
                setIsActive(0);
                break;
            case "/client/testerpanel":
                setIsActive(1);
                break;
            case "/client/panelList":
                setIsActive(2);
                break;
            case "/client/statistics":
                setIsActive(3);
                break;
            case "/client/step-analyze":
                setIsActive(4);
                break;
            case "/client/clientmanage":
                setIsActive(5);
                break;
            default:
                setIsActive(null);
                break;
        }
    }, [history.location])

    const handlePanelScenario = () => {
        if (selectedScenario?.panel?.id) {
            const dataToPass = {
                id: selectedScenario?.panel?.id,
                modified : true
            }
            history.push({
                pathname: '/client/panelDetails',
                state:  dataToPass,
            });
        }else {
            history.push("/client/panellist")
        }
        dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "Scenarios" })
        return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Scenarios3" });
    }   

    const handleSelectedActiveRoute = (route) => {
        switch (route) {
            case "Création de scénario":{
                history.push("/client/createscenario");
                dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "Scenarios" })
                return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Scenarios1" });
            }
            case "Création d'un panel":{
                history.push("/client/testerpanel");
                dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "Scenarios" })
                return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Scenarios2" });
            }
            case "Data":{
                history.push("/client/statistics");
                dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "Statistiques" })
                return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Statistiques1" });
            }
            case "Statistiques":{
                history.push("/client/step-analyze");
                dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "Analyses" })
                return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Analyses1" });
            }
            case "Mon Compte":{
                history.push("/client/clientmanage");
                dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "" })
                return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Mon Compte" });
            }
            default:
                history.push("/client/");
                dispatch({ type: TAB_DASHBOARD_MENU_CHANGED, payload: "" })
                return dispatch({ type: TAB_DASHBOARD_ITEM_CHANGED, payload: "Tableau de Bord" });

        }
    }

    return (
        <div className='favoris-dashboard'>
            <span className='db__title-client'>Mes accès favoris</span>
            <div className='display__flex__row-dashboard'>
                <Button className={isActive === 0 ? "shortcut_client btnIsActive" : "shortcut_client"} onClick={() => handleSelectedActiveRoute("Création de scénario")}>
                    Création de scénario
                </Button>
                <Button  className={isActive === 1 ? "shortcut_client btnIsActive" : "shortcut_client"} onClick={() => handleSelectedActiveRoute("Création d'un panel")}>
                    Création d'un panel
                </Button>
                <Button  className={isActive === 2 ? "shortcut_client btnIsActive" : "shortcut_client"}  onClick={handlePanelScenario}>
                    Panel du scenario
                </Button>
                <Button  className={isActive === 3 ? "shortcut_client btnIsActive" : "shortcut_client"} onClick={() => handleSelectedActiveRoute("Data")}>
                    Retours d'utilisateurs
                </Button>
                <Button  className={isActive === 4 ? "shortcut_client btnIsActive" : "shortcut_client"}  onClick={() => handleSelectedActiveRoute("Statistiques")}>
                    Voir les analyses
                </Button>
                <Button  className={isActive === 5 ? "shortcut_client btnIsActive" : "shortcut_client"} onClick={() => handleSelectedActiveRoute("Mon Compte")}>
                    Mon compte
                </Button>
            </div>
        </div>
    )
}

export default Favorite;