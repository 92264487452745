import React, { useState, useEffect } from "react";
import { Collapse } from 'react-collapse';
import commArrow from '../../../../assets/commArrow.svg'

function ScaleComponentByTester(props) {



  const [max, setMax] = useState(null);
  const [show, setShow] = useState(false);
  const [_, setSelectedAnswer] = useState(null);



  useEffect(() => {
    setMax(props?.ids?.questionChoices?.maxScale);
    setSelectedAnswer(props?.answer);
  }, []);


  const CreateList = () => {
    var values = [];
    let i = 0;
    while (i <= max) {
      values.push(i++);
    }
    return values.map((v) => {
      return (
        <div
          style={
            v === props?.answer
              ? {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 5,
                width: 50,
                borderRadius: 15,
                backgroundColor: "#17A25C",
                gap: 5,
                marginLeft: 30,
              }
              : {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: 5,
                width: 50,
                gap: 5,
                borderRadius: 15,
                backgroundColor: "#F5F5F5",
                marginLeft: 30,
              }
          }
        >
          <span
            style={
              v === props?.answer
                ? { fontSize: 30, fontWeight: "bold", color: "white" }
                : { fontSize: 30, fontWeight: "bold", color: "#17A25C" }
            }
          >
            {v}{" "}
          </span>
        </div>
      );
    });
  };

  return (
    <div className="first-resume">
      <div className="question-resume-container">
        <div style={{ paddingLeft: 10, textAlign: 'start' }}>
          <span className="question-text">
            {props?.ids?.question}
          </span>
        </div>
      </div>
      <div className="scale-tester-array mt-5">
        <span style={{ fontSize: 16, marginRight: 40 }}>Trés mauvaise</span>
        <CreateList />
        <span style={{ fontSize: 16, marginLeft: 40 }}>Trés bonne</span>
      </div>
      {
        props?.comment ?
          <div style={{
            display: 'flex',
            justifyContent: 'start',
            background: 'rgba(196, 196, 196, 0.1)',
            padding: '5px 10px',
            borderRadius: '10px',
            cursor: 'pointer',
            marginTop: '1em'
          }} onClick={() => setShow(!show)}>
            <span style={{
              fontFamily: 'sans-serif',
              color: '#00A359',
              fontSize: '18px',
              paddingLeft: 20
            }}
              className="mr-3">Commentaires</span>
            {
              !show ?
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_0" />
                :
                <img src={commArrow} alt='2M-advisory' className="rotate_commantaire_180" />
            }
          </div>
          : null
      }
      <Collapse isOpened={!show}>
        {props?.comment &&
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '1em', marginTop: '1em' }}>
            <div className="testeur-response">
              <span className="testeur-response-container-Commentaires">
                {props?.comment}.
              </span>
            </div>
          </div>
        }
      </Collapse>
    </div>
  );
}

export default ScaleComponentByTester;
