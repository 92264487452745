import React, { useState, useEffect } from "react";
import OpenStep from "./OpenStep";
import ClosedStep from "./ClosedStep";
import ClosedBarComponent from "./ClosedBarComponent";
import axios from "axios";
import { getAuthorization } from "../../../../services/userServices";
import { apiUrl } from "../../../../config.json";
const apiEndpoint = apiUrl;


function StepResume(props) {
  // const [prps, setPrps] = useState({});
  const [loading, setLoading] = useState(true)
  const [allData, setAllData] = useState([]);
  const [id, setId] = useState();
  const [components, setComponents] = useState([]);

  function getData() {
    return axios
      .get(apiEndpoint + `/api/stats/scenario/${+props?.id}`, {
        headers: getAuthorization(),
      })
      .then((response) => {
        setLoading(false)
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        setLoading(false)

      });
  }


  useEffect(() => {
    setId(props?._id);
    getData().then((data) => {
      if (!data) {
        return null
      }
      const arrayOfCharts = [];
      Object.keys(data).map((key, index) => {
        const object = data[key];
        if (object?.type === "close") {
          const arrayOfLabels = [];
          const arrayOfValue = [];
          object?.answers?.map((val) => {
            if (arrayOfLabels.includes(val?.answer)) {
              arrayOfValue[arrayOfLabels.indexOf(val?.answer)] += val?.count
            } else {
              arrayOfLabels.push(val?.answer);
              arrayOfValue.push(val?.count);
            }
          });
          const arrayOfComment = object?.answers?.map((val) => val?.comment);
          // const arrayOfTesters = object?.answers?.map((val) => val?.testerId);
          arrayOfCharts.push({
            component: (
              <ClosedStep
                {...{
                  labels: arrayOfLabels,
                  data: arrayOfValue,
                  question: object?.question,
                  index: index,
                  comments: arrayOfComment,
                  etape: props?.etape
                }}
              />
            ),
            props: {
              labels: arrayOfLabels,
              data: arrayOfValue,
              question: object?.question,
              index: index
            },
          });
        }
        if (object?.type === "open") {
          const arrayOfLabels = object?.answers?.map((val) => val?.answer);
          const arrayOfNames = object?.answers?.map((val) => `${val?.TesterName} ${val?.TesterLastName.charAt(0)}`);
          const arrayOfComment = object?.answers?.map((val) => val?.comment);
          const arrayOfValue = object?.answers?.map((val) => val?.count);
          arrayOfCharts.push({
            component: (
              <OpenStep
                {...{
                  labels: arrayOfLabels,
                  data: arrayOfValue,
                  question: object?.question,
                  index: index,
                  comments: arrayOfComment,
                  etape: props?.etape,
                  names: arrayOfNames
                }}
              />
            ),
            props: {
              labels: arrayOfLabels,
              data: arrayOfValue,
              question: object?.question,
              index: index,
              names: arrayOfNames
            },
          });
        }
        if (object?.type === "scale") {
          const arrayOfLabels = object?.answers?.map((val) => +val?.answer);
          const arrayOfComment = object?.answers?.map((val) => val?.comment);
          const arrayOfValue = object?.answers?.map((val) => val?.count);
          arrayOfCharts.push({
            component: (
              <ClosedBarComponent
                {...{
                  labels: arrayOfLabels,
                  data: arrayOfValue,
                  question: object?.question,
                  index: index,
                  comments: arrayOfComment,
                  scale: props.scale,
                  etape: props?.etape
                }}
              />
            ),
            type: object?.type,
          });
        }

        setComponents(arrayOfCharts);
      });
      setAllData(data);
    });
  }, [props?._id, props?.etape]);
  return (
    <div>
      {loading && <span style={{ fontWeight: 'bold' }}>Chargement... <br /></span>}
      {components?.length ? components?.map((item) => item?.component) : "Veuillez selectionner un scenario"}
    </div>
  );
}

export default StepResume;
